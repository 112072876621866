/* ==========================================================================
   Layout / Header
   ========================================================================== */

  .header {
    display: block;
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 35px;
    margin-bottom: 45px;

    @include breakpoint(phablet) {
      margin-bottom: 60px;
    }

    @include breakpoint(tablet) {
      margin-top: 75px;
      margin-bottom: 60px;
    }

    @include breakpoint(920px) {
      margin-top: 95px;
      min-height: 480px;
    }

    @include breakpoint(laptop) {
      margin-bottom: 90px;
      min-height: 585px;
    }

    @include breakpoint(desktop) {
      margin-bottom: 80px;
      min-height: 530px;
    }

    @include breakpoint(largedesktop) {
      min-height: 570px;
      margin-bottom: 110px;
    }

    &.noAfter:before {
      display: none;
    }

    &.smallHeader {
      @include breakpoint(tablet) {
        min-height: auto;

        &:after {
          background: rgba(0, 0, 0, 0.35);
        }
      }

      @include breakpoint(tablethor) {
        min-height: 480px;
      }

      @include breakpoint(desktop) {
        margin-bottom: 80px;
      }

      .header-hangs {
        @include breakpoint(tablet) {
          max-width: 835px;
        }
      }
    }

    &.noFeatureImg {
      border-bottom: 2px solid $greyLightBack;
      background: url('./images/visuel-noheader.jpg') center bottom/cover no-repeat;

      @include breakpoint(tablet) {
        min-height: inherit;
        margin-bottom: 50px;
      }

      .header-hangs {
        left: 0;
        @include wrapper();

        @include breakpoint(tablet) {
          padding: 12rem 0 5rem;
          position: relative;
        }

        @include breakpoint(laptop) {
          padding: 17rem 0 0;
          position: relative;
        }

        .h1-title, .subtitle {
          color: $blackColor;
          text-shadow: 0 0 0 transparent;
        }
      }

      .breadcrumb {
        @include wrapper();
        color: $blackColor;

        a {
          color: $blackColor;

          &:after {
            background: $blackColor;
          }
        }
      }

      &:after {
        background: rgba(0, 0, 0, 0.04);
      }

      &:before {
        opacity: 0.2;
      }
    }

    &.hasSlider {
      background: $blackColor!important;
    }

    &:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 0;

      @include breakpoint(tablethor) {
        background: rgba(0, 0, 0, 0.3);
      }
    }

    &:before {
      content: ' ';
      display: block;
      width: 368px;
      height: 380px;
      background: url('images/m-rma.svg') center center/contain no-repeat;
      position: absolute;
      opacity: 0.8;
      left: -35px;
      bottom: -115px;
      z-index: 1;

      @include breakpoint(phablet) {
        width: 485px;
        height: 440px;
      }

      @include breakpoint(tablet) {
        width: 683px;
        height: 574px;
      }
    }

    .pictorma {
      display: block;
      width: 368px;
      height: 380px;
      position: absolute;
      opacity: 0.8;
      left: -35px;
      bottom: -115px;
      z-index: 1;

      @include breakpoint(phablet) {
        width: 485px;
        height: 440px;
      }

      @include breakpoint(tablet) {
        width: 683px;
        height: 574px;
      }
    }

    &-slider {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      overflow: hidden;

      .slide {
        background-size: cover;
        background-position: center center;
      }

      .slick-list, .slick-track, .slick-slider {
        height: 100%;
      }
    }

    &-hangs {
      max-width: 100%;
      position: relative;
      padding: 100px 5% 55px;
      text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      z-index: 1;

      @include breakpoint(phablet) {
        padding: 130px 5% 85px;
      }

      @include breakpoint(tablet) {
        padding: 28% 15px 13%;
        left: 5%;
        max-width: 635px;
      }

      @include breakpoint(tablethor) {
        padding: 23% 25px 8%;
      }

      @include breakpoint(laptop) {
        left: 10%;
        padding: 0;
        position: absolute;
        bottom: 90px;
        text-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
      }

      &.notfullwidth {
        @include breakpoint(tablet) {
          max-width: calc(100% - 370px);
        }

        @include breakpoint(tablethor) {
          max-width: calc(100% - 400px);
        }

        @include breakpoint(1100px) {
          max-width: 605px;
        }
      }

      .subtitle {
        font-family: $special-font-family;
        color: $whiteColor;
        font-size: 28px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.1px;

        @include breakpoint(tablet) {
          font-size: 34px;
          line-height: 1.09;
        }
      }

      .date {
        display: inline-block;
        font-family: $special-font-family;
        color: $whiteColor;
        text-transform: uppercase;
        text-shadow: 0 0 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1px;
      }

      .categoriesart {
        display: table;
        margin-bottom: 15px;

        a {
          @extend .btn-black;
          margin: 0 5px 0 0;
        }
      }
    }

    &-news {
      display: table;
      width: calc(100% - 10px);
      padding: 5px;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 10px;
      position: absolute;
      top: 100%;
      z-index: 1;

      @include breakpoint(tablet) {
        width: auto;
        border: 0;
        padding-bottom: 0;
        position: absolute;
        top: inherit;
        bottom: 65px;
        right: 3%;
      }

      @include breakpoint(laptop) {
        right: 8%;
      }

      @include breakpoint(largedesktop) {
        right: 10%;
      }

      .newscard {
        background: transparent;

        &-img {
          height: 165px;
          overflow: hidden;

          @include breakpoint(phablet) {
            height: auto;
          }
        }

        @include breakpoint(tablet) {
          max-width: 265px;
          padding: 15px 10px 25px;
          background: $backgroundLight;
        }

        @include breakpoint(1100px) {
          max-width: 305px;
        }
      }
    }
  }