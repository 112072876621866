/* ==========================================================================
   Page / Content Pages
   ========================================================================== */

  .page {
    .so-widget-sow-editor-base {
      @include wrapper('min');
      width: 88%;

      @include breakpoint(phablet) {
        margin-bottom: 15px;
      }

      @include breakpoint(tablet) {
        width: 87%;
      }

      @include breakpoint(laptop) {
        margin-bottom: 35px;
      }

      .textwidget {
        margin: auto;

        @include breakpoint(phablet) {
          width: 95%;
        }

        @include breakpoint(laptop) {
          width: 85%;
        }
      }
    }

    .fullwidthtext .so-widget-sow-editor-base {
      .textwidget {
        margin: auto;

        @include breakpoint(phablet) {
          width: 95%;
        }

        @include breakpoint(laptop) {
          width: 100%;
        }
      }
    }

    .widget_sow-editor + .widget_block-testimonials-widget {
      @include breakpoint(tablethor) {
        margin-top: -25px;
      }
    }

    .legal {
      .so-widget-sow-editor-base {
        @include wrapper('min');

        .textwidget {
          width: 100%;
          font-size: 15px;
        }
      }
    }

    .widget_block-contact-widget + .widget_sow-editor {
      margin-top: -25px;
    }
  }