/* ==========================================================================
   Components / Card
   ========================================================================== */

  .card {
    max-height: 280px;
    overflow: hidden;
    list-style-type: none;
    position: relative;
    margin-bottom: 10px;

    @include breakpoint(tablethor) {
      max-height: 305px;
    }

    @include breakpoint(laptop) {
      max-height: inherit;
    }

    &:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      background: $blackColor;
      transition: all $duration $easeInOutCubic;
      opacity: 0.5;
      position: absolute;
      z-index: 0;
    }

    &:after {
      content: ' ';
      display: block;
      width: 93%;
      height: 91%;
      border: 3px solid $yellowColor;
      border-radius: 15px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;

      @include breakpoint(phablet) {
        width: 91%;
        height: 91%;
      }

      @include breakpoint(tablethor) {
        width: 93%;
        height: 90%;
      }

      @include breakpoint(laptop) {
        width: 92%;
        height: 93%;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 2;
    }

    &-title {
      display: block;
      min-height: 3rem;
      font-family: $special-font-family;
      font-size: 20px;
      font-weight: 900;
      line-height: 1.25;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
      padding: 20px 25px 0;
      color: $whiteColor;
    }
  }