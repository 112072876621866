/* ==========================================================================
   Components / News Card
   ========================================================================== */

  .newscard {
    display: block;
    width: calc(100% - 20px);
    background: $backgroundLight;
    transition: all $duration $easeInOutQuart;
    padding: 10px 10px 25px;
    color: $blackColor;
    text-decoration: none;
    position: relative;
    margin-bottom: 15px;

    @include breakpoint(phablet) {
      padding: 10px 10px 5px;
    }

    @include breakpoint(tablet) {
      display: flex;
      flex-direction: column;
      width: 46%;
      flex-basis: 46%;
      padding: 10px 10px 25px;
    }

    @include breakpoint(tablethor) {
      width: 25%;
      flex-basis: 25%;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }

    @include breakpoint(laptop) {
      &:before {
        content: ' ';
        display: block;
        width: 0;
        height: 99.5%;
        border-top: 1px solid $yellowColor;
        border-bottom: 1px solid $yellowColor;
        transition: all $duration $easeInOutQuart;
        padding: 0;
        margin: auto;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
      }

      &:after {
        content: ' ';
        display: block;
        width: 99.5%;
        height: 0;
        border-left: 1px solid $yellowColor;
        border-right: 1px solid $yellowColor;
        transition: all $duration $easeInOutQuart;
        padding: 0;
        margin: auto;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
      }

      &:hover {
        &:before {
          width: 100%;
          transition: all $duration $easeInOutQuart;
        }
        &:after {
          height: 100%;
          transition: all $duration $easeInOutQuart;
        }
        .newscard-title {
          color: darken($yellowColor, 5%);
          transition: all $duration $easeInOutCirc;
        }
      }
    }

    &.featured {
      padding: 10px 10px 15px;
      margin-bottom: 0;

      @include breakpoint(phablet) {
        padding: 10px 10px 5px;
      }

      @include breakpoint(tablet) {
        width: 305px;
        max-width: 285px;
        flex-basis: 100%;
      }

      @include breakpoint(laptop) {
        &:before {
          content: ' ';
          display: block;
          width: 0;
          height: 99%;
          border-top: 3px solid $yellowColor;
          border-bottom: 3px solid $yellowColor;
          transition: all $duration $easeInOutQuart;
          padding: 0;
          margin: auto;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
        }

        &:after {
          content: ' ';
          display: block;
          width: 98.3%;
          height: 0;
          border-left: 3px solid $yellowColor;
          border-right: 3px solid $yellowColor;
          transition: all $duration $easeInOutQuart;
          padding: 0;
          margin: auto;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
        }

        &:hover {
          &:before {
            width: 100%;
            transition: all $duration $easeInOutQuart;
          }
          &:after {
            height: 100%;
            transition: all $duration $easeInOutQuart;
          }
        }
      }

      @include breakpoint(desktop) {
        max-width: 305px;
        width: auto;
      }
    }

    &.greyBackground {
      background: $greyLightBack;
    }

    &-img {
      display: block;
      position: relative;
      margin-bottom: 20px;
      z-index: 1;

      @include breakpoint(phablet) {
        display: inline-block;
        margin-bottom: 0;
        width: 40%;
      }

      @include breakpoint(tablet) {
        display: block;
        margin-bottom: 20px;
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &-date {
      display: table;
      font-size: 9px;
      font-family: $alternativeFont;
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      background: $yellowColor;
      padding: 6px 10px;
      position: absolute;
      left: 15px;
      bottom: 20px;
    }

    &-content {
      padding: 0 8px;
      position: relative;
      flex-grow: 1;
      z-index: 1;

      @include breakpoint(phablet) {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 15px 20px;
      }

      @include breakpoint(tablet) {
        display: block;
        width: auto;
        padding: 0 8px;
      }
    }

    &-title {
      display: block;
      opacity: 0.9;
      font-family: $special-font-family;
      font-size: 20px;
      line-height: 1.2;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 10px;
      transition: all $duration $easeInOutCirc;
    }

    &-excerpt {
      display: block;
      font-family: $alternativeFont;
      font-size: 14px;
      margin-bottom: 15px;
    }
  }