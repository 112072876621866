/* ==========================================================================
   Layout / Bloc Formulaire Landing Page
   ========================================================================== */

  .formLanding {
    padding: 40px 0;

    @include breakpoint(phablet) {
      padding: 60px 0;
    }

    @include breakpoint(tablet) {
      padding: 80px 0;
    }

    @include breakpoint(desktop) {
      padding: 100px 0;
    }

    &__wrapper{
      @include wrapper();
    }

    &__text {
      font-family: $font-stack-common;
      font-weight: 300;
      font-size: 18px;
      max-width: 900px;
      margin-bottom: 20px;

      @include breakpoint(phablet) {
        margin-bottom: 30px;
      }

      @include breakpoint(desktop) {
        margin-bottom: 40px;
      }
    }

    .formWrapper {
      display: flex;

      &__img {
        display: none;

        @include breakpoint(tablet) {
          display: block;
          flex-direction: row;
          width: 45%;
        }

        @include breakpoint(laptop) {
          width: 35%;
        }
      }

      .wpcf7 {
        @include breakpoint(tablet){
          width: 75%;
        }
      }

      .wpcf7-form {
        max-width: none;
      }

      form {
        background-color: #F0F0F0;
        padding: 25px 20px 10px;

        @include breakpoint(phablet) {
          padding: 40px;
        }

        @include breakpoint(tablet) {
          margin-left: 10px;
        }

        @include breakpoint(tablethor) {
          padding: 40px 40px 20px;
        }

        @include breakpoint(laptop) {
          padding: 50px 60px 30px;
        }

        @include breakpoint(desktop) {
          padding: 50px 12% 40px;
        }
      }

      .form {
        &-column {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 15px;

            @include breakpoint(tablet) {
              margin-bottom: 20px;
            }
          }

          &.rpgd-column {
            margin-bottom: 0!important;
          }
        }

        &-row {
          margin-bottom: 15px;

          @include breakpoint(tablethor) {
            display: inline-block;
            vertical-align: top;
            width: 48%;
            margin-bottom: 0;

            input {
              margin-bottom: 0;
            }

            &:first-child {
              margin-right: 3%;
            }

            select {
              background-position: 88% 54%;
            }
          }
        }

        p {
          margin: 0 3px;
        }
      }

      label {
        font-weight: 900;
        margin: 0;
      }

      input,
      textarea {
        box-sizing: border-box;
        width: 100%;
        margin-top: 5px;
        border-radius: 3px;
        border: 2px solid #e3e3e3;
        margin-bottom: 0;
        max-width: 100%;

        &:focus {
          border-width: 2px;
          box-shadow: 0 0 0 0 transparent;
        }

        @include breakpoint(phablet) {
          padding: 18px 20px 18px 20px;
        }
      }

      select {
        margin-top: 5px;
        margin-bottom: 0;
      }

      textarea {
        margin-bottom: 0;
      }

      span.wpcf7-not-valid-tip {
        display: block;
        position: relative;
        top: 0;
        left: 2px;
        margin-top: 0;
        margin-bottom: 10px;
      }

      .error-msg, .errorEmail {
        display: none;
        font-size: 12px;
        font-family: $font-stack-common;
        font-weight: 400;
        color: #f00;
        margin: 8px 5px 0;
      }

      .rgpd {
        font-family: $sans-serif-font;
        font-size: 13px;
      }

      .more {
        @include breakpoint(tablethor){
          padding-left: 30px;
        }

        p {
          display: block;
          margin-top: 10px;
          font-family: $sans-serif-font;
          font-size: 13px;
          font-weight: normal;
          line-height: 19px;
        }

        a {
          color: black;
          text-decoration: underline;
        }
      }
    }

    input[type="checkbox"]:checked + span{
      color: black;
    }

    .wpcf7-form > div:not(#contactform) .wpcf7-not-valid-tip {
      display: none;
    }

    .wpcf7-form > div#contactform input, .wpcf7-form > div#contactform select,
    .wpcf7-form > div#contactform textarea, .wpcf7-form > div#contactform .wpcf7-checkbox {
      display: block;
      margin-bottom: 10px;
    }

    .wpcf7-form > div#contactform .wpcf7-checkbox + .wpcf7-not-valid-tip {
      margin-top: 10px;
    }

    .wpcf7-list-item-label {
      position: relative;
      display: block;
    }

    .wpcf7-submit {
      margin-top: 35px !important;
      box-sizing: border-box;
      background-color: white;
      border-radius: 50px;
      color: black;
      border: 2px solid white;
      width: 230px !important;
      padding: 12px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: none;
      font-weight: bold;
      font-family: $special-font-family;
      line-height: 1;
      font-size: 14px;
      transition: all .6s cubic-bezier(0.77, 0, 0.175, 1);
      &:hover{
        background-color: transparent;
        color: white;
      }
    }

    input[type="checkbox"] + span::after{
      top: 5px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 3px;
    }

    input[type="checkbox"] + span::before{
      border-radius: 3px;
      border-width: 2px;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      margin-bottom: 0;

      @include breakpoint(tablet){
        margin-bottom: 30px;
      }

      @include breakpoint(tablethor){
        margin-bottom: 75px;
      }
    }
  }