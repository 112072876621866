/* ==========================================================================
   Page / Page Produit
   ========================================================================== */

  .product {
    .header {
      background-position: left center;
      position: relative;
      margin-bottom: 30px;

      @include breakpoint(phablet) {
        margin-bottom: 65px;
      }

      @include breakpoint(tablethor) {
        padding-bottom: 55px;
      }

      @include breakpoint(laptop) {
        padding-bottom: 0;
        margin-bottom: 120px;
      }

      &:before {
        display: none;
      }

      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 0;

        @include breakpoint(tablet) {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      &-hangs {
        padding: 100px 15px 50px;
        width: calc(100% - 30px);
        margin: auto;
        left: inherit;
        z-index: 2;

        @include breakpoint(phablet) {
          width: 85%;
          margin: auto;
          padding: 120px 0 80px;
        }

        @include breakpoint(tablet) {
          padding: 175px 0 80px;
          max-width: inherit;
        }

        @include breakpoint(tablethor) {
          width: auto;
          max-width: 500px;
          padding: 45px 40px;
          margin-top: 175px;
          left: 20%;
        }

        @include breakpoint(laptop) {
          position: relative;
          margin-top: 220px;
          left: 25%;
        }

        @include breakpoint(desktop) {
          left: 25%;
          margin-top: 185px;
          bottom: 80px;
        }

        @include breakpoint(mediumdesktop) {
          left: 25%;
          margin-top: 250px;
          bottom: 80px;
        }

        .h1-title-white {
          margin-bottom: 20px;

          @include breakpoint(smallMobile) {
            font-size: 33px;
          }

          @include breakpoint(tablethor) {
            text-shadow: 0 0 0 transparent;
          }
        }
      }

      .pictoffers {
        display: none;
        position: absolute;
        right: 5%;
        bottom: 0;
        height: auto;
        z-index: 1;

        @include breakpoint(tablethor) {
          display: block;
          left: -2%;
          bottom: 10%;
          height: 255px;
        }

        @include breakpoint(laptop) {
          height: 335px;
          left: 10%;
          bottom: -10%;
        }

        @include breakpoint(mediumdesktop) {
          height: 380px;
          left: 15%;
          bottom: -10%;
        }
      }
    }
  }