/* ==========================================================================
   Layout / Bloc composé avec images et texte
   ========================================================================== */

  .blocComposed {
    @include wrapper();
    margin-bottom: 10px;
    position: relative;
    z-index: 2;

    @include breakpoint(tablethor) {
      margin-bottom: 30px;
    }

    @include breakpoint(laptop) {
      display: flex;
      flex-direction: row;
      width: 90%;
      margin-bottom: 75px;
    }

    &-left {
      background: $greyLightBack;

      @include breakpoint(tablet) {
        display: flex;
      }

      @include breakpoint(laptop) {
        flex-basis: 75%;
      }

      @include breakpoint(desktop) {
        flex-basis: 72%;
      }

      .image {
        width: 100%;
        height: 250px;
        background-position: center center;
        background-size: cover;
        overflow: hidden;

        @include breakpoint(phablet) {
          height: 300px;
        }

        @include breakpoint(tablet) {
          width: 50%;
          flex-basis: 50%;
          background-position: center center;
          height: auto;
          max-height: inherit;
        }

        @include breakpoint(tablethor) {
          width: 40%;
          flex-basis: 40%;
        }

        img {
          display: none;
        }
      }

      .text {
        width: calc(100% - 50px);
        padding: 35px 25px;

        @include breakpoint(phablet) {
          padding: 45px 40px;
          width: calc(100% - 80px);
        }

        @include breakpoint(tablet) {
          width: 50%;
          flex-basis: 50%;
          padding: 35px 30px;
        }

        @include breakpoint(tablet) {
          padding: 35px 40px;
        }

        @include breakpoint(tablethor) {
          width: 60%;
          flex-basis: 60%;
        }

        .h1-title {
          font-size: 30px;
          line-height: 1;
          margin-bottom: 20px;

          @include breakpoint(tablet) {
            font-size: 40px;
            margin-bottom: 30px;
          }

          @include breakpoint(tablethor) {
            font-size: 50px;
            line-height: 1.14;
          }

          @include breakpoint(tablethor) {
            width: 80%;
          }
        }

        .text-subtitle {
          display: block;
          font-family: $special-font-family;
          font-size: 25px;
          line-height: 1.1;
          font-weight: 700;
          margin-bottom: 5px;
        }

        .paraSubtitle {
          font-size: 20px;
          line-height: 1.28;
          margin-bottom: 20px;

          @include breakpoint(phablet) {
            font-size: 18px;
          }

          @include breakpoint(tablet) {
            margin-bottom: 30px;
          }
        }

        .contentext {
          width: 98%;
          line-height: 1.28;
        }

        .btn-main-border {
          margin: 30px 0 0;
        }
      }
    }

    &-right {
      display: block;
      background: $yellowColor;

      @include breakpoint(phablet) {
        display: flex;
        flex-direction: row;
        min-height: 200px;
      }

      @include breakpoint(laptop) {
        flex-direction: column;
      }

      @include breakpoint(laptop) {
        flex-basis: 25%;
      }

      @include breakpoint(desktop) {
        flex-basis: 28%;
      }

      .image {
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        position: relative;
        min-height: 230px;

        @include breakpoint(phablet) {
          flex-basis: 50%;
          order: 1;
        }

        @include breakpoint(tablethor) {
          flex-basis: 45%;
        }

        @include breakpoint(laptop) {
          flex-basis: 100%;
          flex-grow: 1;
          background-position: center center;
          order: 0;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 58%;
          }
        }

        .push {
          display: table;
          font-size: 9px;
          font-family: $alternativeFont;
          background: $yellowColor;
          font-weight: 700;
          line-height: 1.3;
          text-transform: uppercase;
          padding: 5px 10px;
          position: absolute;
          left: 20px;
          bottom: 20px;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .text {
        display: block;
        padding: 30px 30px 10px;

        @include breakpoint(phablet) {
          padding: 50px 40px 30px 50px;
          flex-basis: 50%;
          order: 0;
        }

        @include breakpoint(tablet) {
          padding: 60px 50px 50px 60px;
        }

        @include breakpoint(tablethor) {
          flex-basis: 60%;
        }

        @include breakpoint(laptop) {
          flex-basis: inherit;
          padding: 20px 30px 20px 30px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: auto;
            padding: 20px 30px 65px 30px;
          }
        }

        @include breakpoint(desktop) {
          padding: 25px 60px 25px 30px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            padding: 25px 60px 55px 30px;
          }
        }

        &-subtitle {
          display: block;
          font-family: $special-font-family;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.17;
          margin-bottom: 11px;
        }

        .btn-main-larger {
          margin: 20px 0 0;
        }
      }
    }
  }