/* ==========================================================================
   Page / Landing Pages
   ========================================================================== */

  .landing  {
    overflow: hidden;

    main {
      position: relative;
      z-index: 1;
    }
  }

  .navLanding {
    background-color: white;
    min-height: 100px;
    position: relative;

    &:after {
      content: '';
      display: none;
      width: 0;
      height: 0;
      border-top: 50px solid white;
      border-bottom: 20px solid transparent;
      border-left: 49.5vw solid transparent;
      border-right: 49.5vw solid transparent;
      position: absolute;
      z-index: 1;
      left: 0;

      @include breakpoint(phablet) {
        display: block;
      }

      @include breakpoint(tablet){
        top: 100px;
      }
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      max-width: 1380px;
      width: 90%;
      height: 100%;
      margin: auto;

      @include breakpoint(tablet) {
        align-items: center;
      }
    }

    &__retour,
    &__logo,
    &__tel {
      min-width: calc(100%/3);
      display: flex;
      align-items: center;
    }

    &__retour {
      display: flex;
      align-items: first baseline;
      justify-content: flex-start;
      padding: 60px 5px 0;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      color: black;
      font-family: $alternativeFont;

      @include breakpoint(phablet) {
        padding: 30px 0 20px;
      }

      .doublearrow {
        margin-right: 10px;
      }
    }

    &__logo {
      justify-content: flex-end;

      @include breakpoint(phablet) {
        justify-content: center;
      }

      img {
        height: 75px;
        margin-bottom: -15px;
        z-index: 5;

        @include breakpoint(phablet) {
          height: 100px;
          margin-bottom: -40px;
        }

        @include breakpoint(tablet) {
          height: 115px;
          margin-bottom: -50px;
        }
      }
    }

    &__tel {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      position: absolute;
      top: 15px;
      left: 20px;

      @include breakpoint(phablet) {
        position: relative;
        padding: 30px 0 20px;
        top: 0;
        left: 0;
      }

      p {
        display: block;
        font-family: $sans-serif-font;
        line-height: 1;
        font-size: 11px;
        font-weight: 500;

        @include breakpoint(tablet) {
          font-size: 13px;
          margin-bottom: 5px;
        }
      }

      span {
        font-family: $special-font-family;
        font-weight: bold;
        font-size: 20px;
        line-height: 31px;

        @include breakpoint(tablet) {
          font-size: 25px;
        }
      }
    }
  }

  .headerLanding {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include breakpoint(phablet) {
      padding: 50px 0 25px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all .2s ease-in-out;
      z-index: 0;
    }

    &__wrapper{
      @include wrapper();
      display: flex;
      flex-direction: column;
      padding: 40px 30px;
      z-index: 1;
      margin: 0;

      @include breakpoint(phablet) {
        padding: 60px 40px 40px;
      }

      @include breakpoint(tablet) {
        padding: 75px 0 60px;
      }
    }

    &__title,
    &__text,
    &__button{
      color: white;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__text{
      font-family: $alternativeFont;
      font-size: 18px;
      line-height: 21px;
      max-width: 783px;
    }

    &__button{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-family: $special-font-family;
      line-height: 20px;
      box-sizing: border-box;
      color: black;
      border: 2px solid white;
      width: 230px;
      font-size: 14px;
      margin-top: 20px;
      background-color: white;
      border-radius: 50px;
      padding: 12px 30px;

      @include breakpoint(tablet) {
        margin-top: 35px;
      }

      @include breakpoint(laptop) {
        margin-top: 50px;
      }

      &:hover{
        background-color: transparent;
        color: white;
      }
    }
  }