/* ==========================================================================
 Layout / Bloc Cartes des Univers Produits
 ========================================================================== */

  .universes {
    @include wrapper();
    text-align: center;

    @include breakpoint(tablet) {
      margin-bottom: 15px;
    }

    @include breakpoint(tablethor) {
      margin-bottom: 10px;
    }

    @include breakpoint(laptop) {
      width: 90%;
      margin-bottom: 35px;
    }

    @include breakpoint(desktop) {
      margin-bottom: 65px;
    }

    &-container {
      @include flex-row();
      justify-content: space-between;

      @include breakpoint(laptop) {
        justify-content: space-between;
      }

      .card {
        width: 100%;

        @include breakpoint(phablet) {
          width: 48.8%;
        }

        @include breakpoint(tablethor) {
          width: 49.4%;
        }

        @include breakpoint(laptop) {
          width: 24%;
        }
      }
    }

    .h2-title {
      @include breakpoint(tablet) {
        width: 80%;
        margin: auto auto 40px;
        font-size: 30px;
      }

      @include breakpoint(tablethor) {
        width: 70%;
        margin: auto auto 40px;
      }

      @include breakpoint(laptop) {
        font-size: 34px;
        width: auto;
      }

      @include breakpoint(desktop) {
        margin: auto auto 60px;

      }
    }
  }