/* ==========================================================================
   Layout / Bloc composé avec texte et carte google
   ========================================================================== */

  .blockcontact {
    @include wrapper('min');

    @include breakpoint(largedesktop) {
      padding: 0 20%;
    }

    &-flex {
      @include breakpoint(tablet) {
        display: flex;
        justify-content: center;
      }
    }

    &-intro {
      width: 99%;
      margin-bottom: 20px;

      @include breakpoint(tablethor) {
        margin-bottom: 35px;
      }
    }

    &-text {
      margin: 0 3% 10px 0;
      max-width: 700px;
      width: 100%;

      @include breakpoint(tablet) {
        width: auto;
        margin: 0 3% 0 0;
      }
    }

    &-map {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 250px;

      @include breakpoint(tablet) {
        flex-basis: calc(100% - 380px);
      }

      @include breakpoint(tablethor) {
        flex-basis: calc(100% - 400px);
      }

      @include breakpoint(laptop) {
        flex-basis: calc(100% - 650px);
      }

      @include breakpoint(desktop) {
        flex-basis: calc(100% - 745px);
      }

      #map {
        flex-grow: 1;
        margin-bottom: 15px;
        min-height: 350px;

        @include breakpoint(desktop) {
          margin-bottom: 25px;
        }

        #content {
          min-width: 210px;
          font-family: $alternativeFont;
          line-height: 1.6;
          font-size: 15px;
          padding: 5px;
          text-align: left;
        }
      }
    }

    &-coordinates {
      background: $yellowColor;
      padding: 40px 40px 25px;
      font-family: $alternativeFont;
      font-weight: 400;
      letter-spacing: -0.02rem;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;

      @include breakpoint(desktop) {
        padding: 40px 35px 25px;
        flex-direction: row;
      }

      & + .legal {
        margin-bottom: 10%;

        a:not(.btn) {
          text-decoration: underline;
          color: $blackColor;

          &:hover {
            background-color: $yellowColor;
            text-decoration: none;
          }
        }
      }

      strong {
        display: block;
        padding: 0;
        margin-bottom: -10px;
      }

      p {
        line-height: 1.3;
        margin-bottom: 15px;

        small {
          font-size: 13px;
          margin-top: 4px;
          line-height: 1;
        }
      }

      img {
        display: block;
        width: 70%;
        height: auto;
        margin-right: 25px;
        margin-bottom: 25px;

        @include breakpoint(phablet) {
          float: left;
          width: 160px;
          margin-bottom: 10px;
        }

        @include breakpoint(laptop) {
          width: 150px;
        }

        @include breakpoint(desktop) {
          display: inline-block;
          vertical-align: top;
          width: 160px;
          margin-right: 25px;

          & + div {
            display: block;
          }
        }

        @include breakpoint(mediumdesktop) {
          width: 190px;
        }
      }

      div {
        display: block;
      }
    }

    .spolightimg {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
  }
