/* ==========================================================================
   Components / Point clé mis en avant
   ========================================================================== */

  .keypoint {
    margin-bottom: 35px;

    &-title {
      @extend .h3-title;
      padding-left: 35px;
      position: relative;
      margin-bottom: 15px;
      font-weight: 400;

      @include breakpoint(phablet) {
        margin-bottom: 25px;
      }

      &:before {
        content: ' ';
        display: block;
        width: 9px;
        height: 9px;
        border: 2px solid $yellowColor;
        border-radius: 200px;
        position: absolute;
        left: 0;
        top: 10px;
      }
    }

    .wysiwyg {
      width: 100%;
    }
  }