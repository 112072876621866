/* ==========================================================================
   Layout / Bloc découvrir nos autres offres
   ========================================================================== */

  .discoveroffers {
    @include wrapper('min');

    &.centertext {
      text-align: center;
    }

    .offers {
      width: 100%;
    }
  }