/* ==========================================================================
   Layout / Bloc encadré de points clés mis en avant
   ========================================================================== */

  .blockeys {
    display: block;
    border: 5px solid $yellowColor;
    padding: 30px 25px 5px;
    width: calc(90% - 60px);
    margin: 5px auto;

    @include breakpoint(phablet) {
      padding: 40px 55px 20px;
      width: calc(90% - 120px);
    }

    @include breakpoint(tablet) {
      padding: 60px 75px 40px;
      width: calc(80% - 175px);
      border: 10px solid $yellowColor;
      max-width: 735px;
      margin: 35px auto;
    }

    @include breakpoint(laptop) {
      margin: 45px auto 60px;
    }

    @include breakpoint(mediumdesktop) {
      margin: 55px auto 70px;
    }

    .h2-title {
      @include breakpoint(phablet) {
        margin-bottom: 40px;
      }

      @include breakpoint(tablet) {
        margin-bottom: 50px;
      }

      & + .blockeys-intro {
        position: relative;
        bottom: 15px;
      }
    }

    &-intro {
      margin-bottom: 15px;

      @include breakpoint(tablet) {
        margin-bottom: 25px;
      }
    }
  }