/* ==========================================================================
   Components / Formulaires
   ========================================================================== */

  :-webkit-autofill {
    box-shadow: 0 0 0px 1000px $whiteColor inset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  select::-ms-expand {
    display: none;
  }

  label {
    display: block;
    font-size: 15px;
    font-weight: 500;
    font-family: $special-font-family;
    margin-bottom: 20px;
  }

  input, textarea, select {
    width: calc(100% - 25px);
    padding: 15px 10px 15px 15px;
    font-size: 16px;
    font-family: $alternativeFont;
    border: 1px solid darken($greyLightBack, 10%);
    transition: all $duration $easeOutCubic;
    margin-bottom: 15px;

    @include breakpoint(phablet) {
      width: calc(100% - 40px);
      padding: 15px 20px 15px 20px;
    }

    &:focus {
      outline-style: none;
      border: 1px solid $yellowColor;
      box-shadow:inset 0 0 0 2px $yellowColor;
      transition: all $duration $easeOutCubic;
    }
  }

  input[aria-invalid='true'], textarea[aria-invalid='true'], select[aria-invalid='true'] {
    border-color: $redColor;
  }

  span.wpcf7-not-valid-tip {
    display: block;
    color: #f00;
    font-size: 12px;
    font-weight: normal;
    position: relative;
    bottom: 8px;
    margin-bottom: 5px;
  }

  textarea {
    width: calc(100% - 65px);
    padding: 20px 40px 15px 25px;
  }

  select {
    width: 100.4%;
    font-family: $special-font-family;
    font-weight: 500;
    background-color: $backgroundLight;
    background-image: url('images/picto-fleche.svg');
    background-repeat: no-repeat;
    background-position: 95% 53%;
    background-size: 18px 9px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type="checkbox"] {
    display: none!important;
    position: relative;

    & + span, & + label {
      box-sizing: border-box;
      padding-left: 35px;
      position: relative;
      cursor: pointer;
      transition: all $duration $easeInOutQuint;

      &:after {
        content: ' ';
        display: block;
        width: 15px;
        height: 15px;
        background: url('images/icone-check.svg') center center/15px 15px no-repeat;
        transition: all $duration $easeInOutQuint;
        opacity: 0;
        transform: scale(0.5);
        position: absolute;
        top: 2px;
        left: 3px;

        @-moz-document url-prefix() {
          top: 18px;
        }
      }

      &:before {
        content: ' ';
        display: block;
        width: 15px;
        height: 15px;
        border: 2px solid darken($greyLightBack, 10%);
        transition: all $duration $easeInOutQuint;
        position: absolute;
        top: 1px;
        left: 0;
      }
    }
  }

  input[type="checkbox"]:checked {
    display: none!important;
    position: relative;

    & + span, & + label {
      cursor: pointer;
      color: darken($yellowColor, 3%);
      transition: all $duration $easeInOutQuint;

      &:before {
        border: 2px solid $yellowColor;
        transition: all $duration $easeInOutQuint;
      }

      &:after {
        opacity: 1.0;
        transform: scale(1);
        transition: all $duration $easeInOutQuint;
      }
    }
  }

  span.wpcf7-list-item {
    display: block!important;
    margin: 0!important;
  }

  .block-50-50 {
    display: flex;
    justify-content: space-between;

    @include breakpoint(smallMobile) {
      display: block;
    }

    .wpcf7-form-control-wrap {
      flex-basis: 48.5%;
    }
  }

  .block-70-30 {
    display: flex;
    justify-content: space-between;

    @include breakpoint(smallMobile) {
      display: block;
    }

    span {
      flex-basis: 60%;
    }

    span:last-child {
      flex-basis: 37%;
    }
  }

  .block-60-40 {
    display: flex;
    justify-content: space-between;

    @include breakpoint(smallMobile) {
      display: block;
    }

    span {
      flex-basis: 48%;
    }

    span:last-child {
      flex-basis: 49%;
    }
  }

  .wpcf7-submit {
    @extend .btn-submit;
    display: table;
    width: auto;
  }

  div.wpcf7-response-output {
    font-family: $alternativeFont;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 25px;
    margin: 2em 0 1em;
  }

  .wpcf7-form {
    max-width: 690px;
    margin: 0;

    @include breakpoint(phablet) {
      margin: auto;
    }

    @include breakpoint(tablet) {
      margin: 0;
    }

    form br{ display: none;}
  }

  .searchbox {
    max-width: 580px;
    width: 100%;
    position: relative;
    margin: 0 auto;

    input {
      max-width: 500px;
      width: calc(100% - 60px);
      padding: 25px 40px 25px 20px;
      font-size: 18px;
      font-family: $alternativeFont;
      border: 1px solid darken($greyLightBack, 10%);
      margin: 5px 0;

      @include breakpoint(phablet) {
        width: calc(100% - 90px);
        padding: 25px 60px 25px 30px;
        margin: 30px 0;
      }

      &:focus {
        outline-style: none;
        border: 1px solid $yellowColor;
      }
    }

    .btn-search {
      display: block;
      width: 25px;
      height: 25px;
      text-indent: -999999999px;
      background: url('images/icone-loupe.svg') center center/24px 25px no-repeat;
      transition: all $duration $easeOutCubic;
      position: absolute;
      padding: 20px;
      top: 24px;
      right: 14px;
      opacity: 0.3;

      @include breakpoint(phablet) {
        top: 47px;
        right: 8px;
      }

      &:hover {
        opacity: 1.0;
        transition: all $duration $easeOutCubic;
      }
    }
  }

  .form {
    position: relative;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background: transparentize(#f0f0f0, 0.1);
    transition: all $duration $easeInOutCubic;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    &.show {
      opacity: 1;
      visibility: visible;
      transition: all $duration $easeInOutCubic;
    }

    .ldio-vzypg78lhoh div { box-sizing: border-box!important }
    .ldio-vzypg78lhoh > div {
      position: absolute;
      width: 102px;
      height: 102px;
      top: 49px;
      left: 49px;
      border-radius: 50%;
      border: 6px solid #000;
      border-color: #969696 transparent #969696 transparent;
      animation: ldio-vzypg78lhoh 1s linear infinite;
    }

    .ldio-vzypg78lhoh > div:nth-child(2) { border-color: transparent }
    .ldio-vzypg78lhoh > div:nth-child(2) div {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotate(45deg);
    }

    .ldio-vzypg78lhoh > div:nth-child(2) div:before, .ldio-vzypg78lhoh > div:nth-child(2) div:after {
      content: "";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      top: -6px;
      left: 42px;
      background: #969696;
      border-radius: 50%;
      box-shadow: 0 96px 0 0 #969696;
    }

    .ldio-vzypg78lhoh > div:nth-child(2) div:after {
      left: -6px;
      top: 42px;
      box-shadow: 96px 0 0 0 #969696;
    }

    .loadingio-spinner-dual-ring-ehse9z78uy {
      width: 200px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      background: transparent;
    }

    .ldio-vzypg78lhoh {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-vzypg78lhoh div { box-sizing: content-box; }
  }

  .confirmation-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    opacity: 0;
    visibility: hidden;
    transition: all $duration $easeInOutCubic;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    &.show {
      opacity: 1;
      visibility: visible;
      transition: all $duration $easeInOutCubic;
    }

    h2 {
      @extend .h1-title;
    }

    p {
      font-size: 20px;
      line-height: 1.5;
    }
  }

  @keyframes ldio-vzypg78lhoh {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }

