/* ==========================================================================
   Components / WYSIWYG
   ========================================================================== */

  .wysiwyg, .textwidget {
    margin: auto;
    position: relative;
    z-index: 2;

    h1 {
      display: block;
      @extend .h1-title;
      margin-bottom: 30px;
    }

    h2 {
      @extend .h2-title;
      display: block;
      margin-bottom: 35px;
    }

    h3 {
      @extend .h3-title;
      display: block;
      margin-bottom: 25px;
    }

    p {
      //clear: both;
      display: block;
      line-height: 1.28;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

       & + h3 {
         @include breakpoint(tablet) {
           margin-top: 25px;
         }
       }
    }

    b {
      font-weight: 700;
    }

    a:not(.btn) {
      text-decoration: underline;
      color: $blackColor;

      &:hover {
        background-color: $yellowColor;
        text-decoration: none;
      }
    }

    ul {
      margin-bottom: 25px;

      & + h3, & + h2 {
        margin-top: 30px;
      }

      li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 5px;

        &:before {
          content: ' ';
          display: block;
          width: 5px;
          height: 5px;
          background: $yellowColor;
          border-radius: 1200px;
          position: absolute;
          left: 0;
          top: 9px;
        }
      }
    }

    .alignleft,
    .alignright {
      display: block;
      margin: auto;
      margin-top: 5px;
      margin-bottom: 30px;
    }

    @include breakpoint(phablet) {
      .alignleft {
        float: left;
        margin-right: 45px;
        margin-bottom: 35px;
      }
      .alignright {
        float: right;
        margin-left: 45px;
        margin-bottom: 25px;
      }
    }
  }

  div.legal {
    font-size: 13px;
    font-style: italic;
    opacity: 0.7;
    margin: 5px 0 30px;
  }