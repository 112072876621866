/* ==========================================================================
   Layout / Bloc avec pictogrammes
   ========================================================================== */

  .blockpictos {
    display: block;
    width: 100%;
    text-align: center;
    padding: 45px 0 25px;
    position: relative;
    z-index: 2;
    margin: 35px 0 15px;

    @include breakpoint(phablet) {
      margin: 35px 0;
      padding: 55px 0 25px;
    }

    @include breakpoint(tablet) {
      margin: 75px 0 35px;
    }

    @include breakpoint(tablethor) {
      padding: 60px 0 35px;
      margin: 45px 0;
    }

    @include breakpoint(laptop) {
      padding: 65px 0 30px;
      margin: 0 0 50px;
    }

    @include breakpoint(desktop) {
      padding: 65px 0 30px 0;
      margin: 0 0 75px;
    }

    &.bg-yellow {  background: $yellowColor;  }
    &.bg-grey {  background: $greyLightBack;  }

    .wrapper {
      @include wrapper();
      padding: 0 10px;
    }

    .h1-title {
      font-size: 30px;

      @include breakpoint(tablet) {
        font-size: 40px;
        margin-bottom: 15px;
      }

      & + .pictoslist {
        margin-top: 25px;

        @include breakpoint(tablet) {
          margin-top: 55px;
        }
      }
    }

    .subtitle {
      font-family: $alternativeFont;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.02rem;

      @include breakpoint(tablet) {
        font-size: 22px;
      }

      @include breakpoint(laptop) {
        width: 85%;
        margin: auto;
      }

      & + .pictoslist {
        margin-top: 25px;

        @include breakpoint(tablet) {
          margin-top: 45px;
        }
      }
    }

    .pictoslist {
      display: block;

      @include breakpoint(phablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      &.border-yellow {
        .pictoslist-picto {
          background: transparent;
          border: 3px solid $yellowColor;
        }
      }

      &.border-bg-white {
        .pictoslist-picto {
          background: $backgroundLight;
        }
      }

      &-elem {
        margin-bottom: 30px;

        @include breakpoint(phablet) {
          flex-basis: 50%;
          margin-bottom: 50px;
        }

        @include breakpoint(tablet) {
          flex-basis: 25%;
        }

        @include breakpoint(tablethor) {
          flex-basis: 20%;
        }

        a {
          color: $blackColor;

          &:hover {
            .pictoslist-picto {

            }
          }
        }
      }

      &-picto {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        padding: 15px;
        box-sizing: border-box;
        margin: auto auto 30px;
        text-align: center;
        border-radius: 800px;
        position: relative;

        @include breakpoint(tablet) {
          width: 80px;
          height: 80px;
        }

        @include breakpoint(tablethor) {
          width: 115px;
          height: 115px;
        }

        img {
          width: 70%;
          height: auto;
          margin: auto;
        }
      }

      &-text {
        display: block;
        font-size: 16px;
        font-family: $special-font-family;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .text-yellow {
      color: $yellowColor;
      a { color: $yellowColor; }
    }

    .text-white {
      color: $whiteColor;
      a { color: $whiteColor; }
    }

    .btn {
      margin: 0 auto 25px;
    }

    .number {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateY(50%);
      font-family: $special-font-family;
      font-size: 29px;
      letter-spacing: 1.38px;
      font-weight: 800;
    }
  }

  .landing .blockpictos {
    background-color: #F0F0F0;
    margin: 0;

    h1 {
      margin-bottom: 25px;

      @include breakpoint(tablet) {
        font-size: 50px;
      }
    }

    .subtitle {
      line-height: 23px;
      font-size: 18px;
      font-family: $font-stack-common;
      font-weight: 300;
      max-width: 630px;
      margin: auto;

      & + .pictoslist {
        margin-top: 10px;
      }
    }

    .pictoslist-text {
      font-size: 18px;
      font-family: $special-font-family;
      font-weight: normal;
      text-transform: none;
    }

    .pictoslist-elem {
      margin: 30px 15px;

      @include breakpoint(tablethor) {
        margin: 15px;
        flex-basis: 29%;
      }
    }

    .btn-main-border {
      margin-top: 25px;
    }
  }