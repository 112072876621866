/* ==========================================================================
   Components / Push Product
   ========================================================================== */

  .pushproduct {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: $yellowColor;
    flex-basis: 100%;
    text-align: center;
    padding: 30px 0;
    margin-bottom: 10px;

    @include breakpoint(phablet) {
      padding: 40px 0;
    }

    @include breakpoint(tablet) {
      padding: 50px 0;
      flex-basis: 99.55%;
    }

    @include breakpoint(laptop) {
      flex-basis: 49.35%;
    }

    &.adaptToThreeBlock {
      @include breakpoint(phablet) {
        flex-basis: 49%;
        padding: 35px 0;
      }

      @include breakpoint(tablet) {
        flex-basis: 49.25%;
        padding: 35px 0;
      }

      @include breakpoint(tablethor) {
        flex-basis: 99.9%;
        padding: 50px 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 24%;
        padding: 30px 0;

        .pushproduct-title {
          @include breakpoint(tablet) {
            margin-bottom: 15px;
          }
        }

        .pushproduct-text {
          @include breakpoint(tablet) {
            margin-bottom: 15px;
          }
        }
      }
    }

    &.adaptToFourBlock {
      @include breakpoint(tablethor) {
        flex-basis: 66.12%;
        padding: 50px 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 99.75%;
      }
    }

    &.adaptToFiveBlock {
      @include breakpoint(phablet) {
        flex-basis: 49%;
        padding: 20px 0;
      }

      @include breakpoint(tablet) {
        flex-basis: 49.2%;
        padding: 20px 0;
      }

      @include breakpoint(tablethor) {
        flex-basis: 32.4%;
        padding: 20px 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 74.65%;
      }

      .pushproduct-title {
        @include breakpoint(tablet) {
          margin-bottom: 15px;
        }

        @include breakpoint(tablethor) {
          margin-bottom: 30px;
        }
      }

      .pushproduct-text {
        @include breakpoint(tablet) {
          margin-bottom: 15px;
        }

        @include breakpoint(tablethor) {
          margin-bottom: 35px;
        }
      }
    }

    &.adaptToSixorMoreBlock {
      @include breakpoint(phablet) {
        flex-basis: 49%;
        padding: 20px 0;
      }

      @include breakpoint(tablet) {
        flex-basis: 49.2%;
      }

      @include breakpoint(tablethor) {
        flex-basis: 66%;
      }

      @include breakpoint(laptop) {
        flex-basis: 49.4%;
      }
    }

    &-content {
      width: 85%;
      margin: auto;
      text-align: center;
      position: relative;
      z-index: 2;

      @include breakpoint(phablet) {
        margin: inherit;
      }

      .btn {
        margin: 15px auto 0;
      }
    }

    &-title {
      @extend .h3-title;
      margin-bottom: 10px;

      @include breakpoint(tablet) {
        margin-bottom: 30px;
      }
    }

    &-text {
      margin-bottom: 20px;

      @include breakpoint(tablet) {
        margin-bottom: 35px;
      }
    }
  }