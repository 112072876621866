/* ==========================================================================
   Layout / Bloc Engagements Footer
   ========================================================================== */

  .engagements {
    @include wrapper('min');
    display: none;

    @include breakpoint(tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
      position: relative;
      top: 30px;
    }

    &-title {
      font-family: $special-font-family;
      font-size: 24px;
      font-weight: 500;
      color: $blackColor;
      flex-basis: 35%;
      position: relative;

      @include breakpoint(tablethor) {
        flex-basis: 28%;
      }

      @include breakpoint(laptop) {
        flex-basis: 25%;
      }

      &:after {
        content: ' ';
        display: block;
        width: 3px;
        height: 90px;
        background: $yellowColor;
        position: absolute;
        right: 15px;
        top: -27px;

        @include breakpoint(tablethor) {
          right: 10px;
        }
      }
    }

    &-logos {
      flex-basis: 60%;
      text-align: right;

      @include breakpoint(tablethor) {
        flex-basis: 70%;
      }

      @include breakpoint(laptop) {
        flex-basis: 65%;
      }

      img {
        max-width: 55px;
        height: auto;

        @include breakpoint(tablethor) {
          max-width: 75px;
        }

        &:not(:last-child) {
          margin-right: 5%;

          @include breakpoint(tablethor) {
            margin-right: 7%;
          }

          @include breakpoint(laptop) {
            margin-right: 10%;
          }
        }
      }
    }
  }