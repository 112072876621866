/* ==========================================================================
   Layout / Footer
   ========================================================================== */

  .footer {
    display: block;
    width: 100%;
    background: $blackColor;
    border-top: 5px solid $yellowColor;
    padding: 50px 0 15px;
    margin-top: 60px;
    position: relative;
    overflow: hidden;

    @include breakpoint(phablet) {
      padding: 75px 0 55px;
    }

    @include breakpoint(tablet) {
      padding: 75px 0 35px;
      margin-top: 80px;
    }

    &:after {
      content: ' ';
      display: block;
      width: 705px;
      height: 100%;
      background: url(images/m-rma.svg) center center/705px auto no-repeat;
      position: absolute;
      top: 0;
      right: -160px;
      opacity: 0.4;

      @include breakpoint(phablet) {
        width: 735px;
        height: 105%;
        background: url(images/m-rma.svg) center center/735px auto no-repeat;
        right: -140px;
      }
    }

    &-wrapper {
      @include wrapper();
      width: 85%;

      @include breakpoint(phablet) {
        width: 88%;
      }
    }

    &-infos {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 5px;
      border-bottom: 3px solid rgba(255, 255, 255, 0.3);
      padding-bottom: 10px;
      margin-bottom: 25px;

      @include breakpoint(phablet) {
        padding-bottom: 40px;
      }

      @include breakpoint(laptop) {
        padding: 0 25px;
        padding-bottom: 40px;
      }

      @include breakpoint(laptop) {
        flex-wrap: nowrap;
      }
    }

    &-about {
      display: block;
      flex-basis: 100%;
      color: $whiteColor;
      margin-bottom: 10px;
      order: 0;

      @include breakpoint(phablet) {
        flex-basis: 60%;
        margin-bottom: 30px;
      }

      @include breakpoint(tablet) {
        flex-basis: 50%;
      }

      @include breakpoint(tablethor) {
        flex-basis: 30%;
        margin-bottom: 0;
      }

      @include breakpoint(1086px) {
        flex-basis: 35%;
      }

      @include breakpoint(laptop) {
        flex-basis: 30%;
      }

      .textAbout {
        margin: 0;
        width: 100%;
        padding: 0;
        font-size: 14px;
        font-family: $sans-serif-font;
        font-weight: 300;
        line-height: 1.4;
        margin-top: 10px;
        margin-bottom: 10px;

        .textwidget {
          width: 100%;

          p {
            margin-bottom: 0;
            line-height: 1.5;
          }

          small {
            display: block;
            font-size: 12px;
            margin-top: 16px;
            color: $yellowColor;
            font-style: italic;
          }
        }
      }
    }

    &-menu {
      flex-basis: 60%;
      margin-bottom: 30px;
      padding-left: 5px;
      position: relative;
      z-index: 2;
      order: 2;

      @include breakpoint(phablet) {
        flex-basis: 32%;
        padding-left: 5px;
        margin-bottom: 70px;
        order: 0;
      }

      @include breakpoint(tablet) {
        flex-basis: 40%;
      }

      @include breakpoint(tablethor) {
        flex-basis: 20%;
        margin-bottom: 25px;
      }

      @include breakpoint(laptop) {
        flex-basis: 16%;
        padding-left: 5px;
      }

      li:not(:last-child) a {
        margin-bottom: 13px;
      }

      a {
        display: block;
        font-family: $special-font-family;
        color: $whiteColor;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 0.7px;
        transition: all $duration $easeInOutQuart;
        text-decoration: none;

        &:hover {
          color: $yellowColor;
          transition: all $duration $easeInOutQuart;
        }
      }
    }

    &-video {
      flex-basis: 100%;
      position: relative;
      margin-bottom: 25px;
      order: 1;

      @include breakpoint(phablet) {
        flex-basis: 65%;
        margin-bottom: 0;
        order: 0;
      }

      @include breakpoint(tablet) {
        flex-basis: 50%;
      }

      @include breakpoint(tablethor) {
        flex-basis: 380px;
      }

      .image {
        display: block;
        position: relative;
        z-index: 1;

        img {
          display: block;
          width: 100%;
          height: auto;
        }

        &:before {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .content {
        display: block;
        text-align: center;
        color: $whiteColor;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
        margin: auto;
        z-index: 2;

        @include breakpoint(smallMobile) {
          bottom: 0;
        }

        @include breakpoint(phablet) {
          bottom: 25px;
        }

        .h2-title {
          margin: 0;
        }
      }
    }

    &-social {
      flex-basis: 22%;
      margin-bottom: 45px;
      order: 3;

      @include breakpoint(phablet) {
        flex-basis: 25%;
        order: 0;
      }

      @include breakpoint(tablet) {
        flex-basis: 40%;
      }

      @include breakpoint(tablethor) {
        flex-basis: 100%;
        margin-top: 10px;
        margin-bottom: 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 4%;
      }

      .social {
        position: relative;
        z-index: 1;

        @include breakpoint(laptop) {
          padding: 15px 0;
        }

        li {
          display: block;

          &:not(:last-child) a {
            margin-bottom: 22px;
          }

          @include breakpoint(tablethor) {
            display: inline-block;
            vertical-align: top;

            &:not(:last-child) a {
              margin-bottom: 0;
            }
          }

          &:not(:last-child) {
            margin-right: 15px;
          }

          @include breakpoint(laptop) {
            display: block;

            &:not(:last-child) a {
              margin-bottom: 22px;
            }
          }
        }

        a {
          display: block;
          width: 40px;
          height: 40px;
          background-size: 100%;
          background-repeat: no-repeat;
          //background-position: center center;
          transition: all $duration $easeInOutQuart;

          @include breakpoint(laptop) {
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
            background-size: unset;
            background-position: unset;
          }

          svg {
            fill: $backgroundLight;
            transition: all $duration $easeInOutQuart;
          }

          &:hover svg {
            fill: $yellowColor;
            transition: all $duration $easeInOutQuart;
          }
        }
      }
    }

    &-links {
      position: relative;
      z-index: 2;

      .logovyv {
        display: block;

        @include breakpoint(phablet) {
          position: absolute;
          top: 0;
          left: 0;
        }

        @include breakpoint(tablet) {
          display: inline-block;
          vertical-align: top;
          position: relative;
        }

        @include breakpoint(tablethor) {
          display: block;
          position: absolute;
        }
      }

      a {
        color: $whiteColor;
        font-size: 13px;
        font-family: $sans-serif-font;
        font-weight: 300;
        line-height: 1;
        transition: all $durationSmall $easeInOutQuart;
        text-decoration: none;

        @include breakpoint(smallMobile) {
          font-size: 12px;
        }

        &:hover {
          color: $yellowColor;
          transition: all $durationSmall $easeInOutQuart;
        }
      }

      .menuLegal {
        display: block;
        text-align: left;
        padding: 15px 0;

        @include breakpoint(phablet) {
          text-align: right;
        }

        @include breakpoint(tablet) {
          display: inline-block;
          vertical-align: top;
          text-align: center;
          margin-left: 30px;
        }

        @include breakpoint(tablethor) {
          display: block;
          margin-left: 0;
        }

        li {
          display: inline-block;
          margin-bottom: 5px;

          @include breakpoint(phablet) {
            margin-bottom: 0;
          }

          &:not(:last-child) {
            margin-right: 15px;

            @include breakpoint(phablet) {
              margin-right: 20px;
            }
          }
        }
      }

      .gingerlink {
        display: block;
        position: absolute;
        top: 4px;
        right: 0;
        z-index: 1;

        @include breakpoint(phablet) {
          position: relative;
          margin: auto;
          text-align: center;
        }

        @include breakpoint(laptop) {
          position: absolute;
          display: inline-block;
          float: right;
        }

        &:after {
          content: ' ';
          display: inline-block;
          width: 27px;
          height: 27px;
          background: url('images/logo-gingerminds.svg') center center/ 27px 27px no-repeat;
          margin-left: 12px;
          position: relative;
          top: 7px;

          @include breakpoint(smallMobile) {
            margin-left: 8px;
          }
        }
      }
    }
  }
  #socle-content {
    position: relative !important;
    background: #000;
    z-index: 2;
    left: auto;
    right: 0;
    transition: all .5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
