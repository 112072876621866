/* ==========================================================================
   Layout / Bloc Slider Infos
   ========================================================================== */

  .sliderInfos {
    padding-top: 20px;

    @include breakpoint(phablet) {
      padding-top: 50px;
    }

    @include breakpoint(laptop) {
      padding-top: 80px;
    }

    @include breakpoint(desktop) {
      padding-top: 100px;
    }

    &__wrapper {
      @include wrapper();
      position: relative;
    }

    &__title {
      color: black;
      margin-bottom: 30px;
    }

    .chevrons {
      position: absolute;
      top: 47px;
      right: 0;

      @include breakpoint(phablet) {
        top: 20px;
      }

      .sliderprev, .slidernext {
        width: 25px;
        height: 25px;

        @include breakpoint(tablet) {
          width: 30px;
          height: 30px;
        }
      }

      .sliderprev {
        transform: rotate(90deg);
        cursor: pointer;
        margin-right: 15px;

        @include breakpoint(tablet) {
          margin-right: 50px;
        }
      }

      .slidernext {
        transform: rotate(-90deg);
        cursor: pointer;
      }
    }

    .sliderWrapper {
      display: flex;

      .slick-slide {
        margin: 0 5px;
        height: inherit !important;
      }

      .slick-list {
        margin: 0 -5px;
      }

      .slick-track {
        display: flex !important;
      }

      &__hover{
        &:hover {
          color: white;
          transition: all $durationSmall $easeInOutCirc;

          .doublearrow {
            fill: white;
          }
        }
      }

      &__slide {
        display: flex !important;
        flex-direction: column;
        background: #F0F0F0;
        padding: 30px 20px;
        color: black;
        box-sizing: border-box;
        text-align: left;
        height: 100%;
        transition: all $durationSmall $easeInOutCirc;

        h3 {
          font-size: 20px;
          font-family: $special-font-family;
          font-weight: 800;
          text-transform: uppercase;
        }

        p {
          font-size: 15px;
          font-family: $alternativeFont;
        }

        span {
          margin-top: auto;
          padding-top: 15px;
          font-size: 13px;
          font-family: $alternativeFont;
          font-weight: bold;
          display: flex;
          align-items: baseline;
          position: relative;
        }

        .doublearrow {
          transform: rotate(180deg);
          width: 8px;
          height: 8px;
          margin-left: 4px;
          transition: all .6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          position: relative;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            top: 1px;
          }
        }
      }
    }

    .cd-popup {
      display: block;
      width: 100%;
      height: 100vh;
      opacity: 0;
      visibility: hidden;
      background: rgba(0,0,0,0.5);
      transition: opacity 0.3s 0s, visibility 0s 0.3s;
      box-sizing: border-box;
      padding: 10px 5px;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 15;

      @include breakpoint(tablet) {
        padding: 20px;
      }

      @include breakpoint(laptop) {
        padding: 30px;
      }

      &-container {
        display: block;
        width: calc(100% - 20px);
        height: auto;
        max-width: 1380px;
        max-height: 100%;
        box-sizing: border-box;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateX(0%) translateY(-50%);
        overflow-y: auto;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
        padding: 30px 25px;
        margin: auto;
        position: relative;

        @include breakpoint(phablet) {
          padding: 40px;
        }

        @include breakpoint(tablet) {
          padding: 50px;
        }

        @include breakpoint(laptop) {
          padding: 60px 80px;
        }

        h1 {
          margin-bottom: 15px;

          @include breakpoint(phablet) {
            margin-bottom: 20px;
          }

          @include breakpoint(laptop) {
            margin-bottom: 30px;
          }
        }

        h3 {
          margin-bottom: 15px;

          @include breakpoint(phablet) {
            margin-bottom: 25px;
          }

          @include breakpoint(tablethor) {
            margin-bottom: 30px;
          }
        }

        img {
          max-width: 100%;
          height: auto;
        }

        p {
          font-size: 18px;
          font-family: $font-stack-common;
          font-weight: 300;
        }

        li {
          margin-bottom: 15px;
          font-size: 14px;
          font-family: $alternativeFont;
          font-weight: 400;
          letter-spacing: 0.65px;

          &:before {
            top: 6px;
          }
        }
      }
    }

    .cd-popup.is-visible {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s 0s, visibility 0s 0s;
    }

    .cd-popup-close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 70px;
      height: 40px;
      z-index: 10;

      @include breakpoint(tablethor) {
        top: 30px;
        right: 30px;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 25px;
        height: 3px;
        background-color: black;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .etapes {
      display: flex;
      flex-direction: column;

      .etape {
        display: flex;
        flex-direction: column;

        @include breakpoint(tablet) {
          flex-direction: row;
        }

        &:not(:last-child) {
          margin-bottom: 20px;

          @include breakpoint(phablet) {
            margin-bottom: 30px;
          }

          @include breakpoint(laptop) {
            margin-bottom: 40px;
          }
        }

        &__text,
        &__image {
          width: 100%;
          @include breakpoint(tablet) {
            width: 50%;
          }
        }

        &__text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          background-color: #f0f0f0;
          padding: 25px;
          box-sizing: border-box;

          @include breakpoint(phablet) {
            padding: 35px 25px;
          }
        }

        &__image {
          min-height: 220px;

          @include breakpoint(tablet) {
            min-height: 360px;
          }
        }

        &__titre {
          letter-spacing: 1.13px;
          line-height: 1.2;
          font-size: 25px;
          font-family: $special-font-family;
          font-weight: bold;
          padding: 20px 0 10px 0;

          @include breakpoint(tablethor) {
            font-size: 34px;
            line-height: 37px;
          }
        }

        &__stitre {
          font-size: 20px;
          font-family: $special-font-family;
          font-weight: 500;

          @include breakpoint(tablethor) {
            font-size: 24px;
          }
        }

        &__chiffre {
          font-size: 48px;
          font-family: $special-font-family;
          font-weight: bold;
        }

        &:nth-of-type(2n) {
          @include breakpoint(tablet) {
            .etape__text {
              order: 1;
            }
          }
        }
      }
    }
  }