/* ==========================================================================
   Layout / Bloc d'introduction de page
   ========================================================================== */

  .contactblock {
    @include wrapper('min');

    @include breakpoint(phablet) {
      margin-bottom: 35px;
    }

    &-surtitle {
      @extend .h2-title;
      text-align: center;
      margin-bottom: 20px;

      @include breakpoint(tablet) {
        margin-bottom: 30px;
        text-align: left;
      }
    }

    &-blocks {
      display: block;

      @include breakpoint(tablet) {
        display: flex;
        justify-content: space-between;
      }
    }

    &-elem {
      width: calc(100% - 20px);
      text-align: center;
      color: $whiteColor;
      background: $blackColor;
      padding: 30px 10px;
      margin-bottom: 10px;

      @include breakpoint(tablet) {
        flex-basis: 50%;
        border-left: 5px solid $whiteColor;
        padding: 40px 10px;
        margin-bottom: 0;
      }

      @include breakpoint(tablethor) {
        padding: 60px 10px;
      }

      &.fullwidth{
        @include breakpoint(tablet) {
          flex-basis: 100%;
          border: 0;
        }

        @include breakpoint(tablethor) {
          display: flex;
          justify-content: center;
          align-items: center;

          .contactblock-title {
            margin: 0 auto 0 5%;
          }

          .contactblock-text {
            margin: 0!important;
          }
        }

        @include breakpoint(laptop) {
          .contactblock-title {
            margin: 0 auto 0 10%;
          }
        }
      }

      &.yellowColor {
        color: $blackColor;
        background: $yellowColor;

        @include breakpoint(tablet) {
          border-left: 0;
          border-right: 5px solid $whiteColor;
        }

        .contactblock-title {
          padding-top: 0;
        }
        .contactblock-text {
          font-family: $font-stack-common;
          letter-spacing: 0;
          margin-bottom: 20px;
        }
      }
    }

    &-title {
      @extend .h3-title;
      width: 90%;
      max-width: 400px;
      margin: 0 auto 15px;
      padding-top: 15px;

      @include breakpoint(tablethor) {
        width: 80%;
        margin: 0 auto 25px;
      }
    }

    &-text {
      font-family: $alternativeFont;
      letter-spacing: 0.01rem;
      margin: auto auto 25px;

      @include breakpoint(tablet) {
        width: 80%;
      }

      @include breakpoint(desktop) {
        width: 50%;
      }
    }

    .btn {
      margin: 0 auto;
      position: relative;
    }
  }