/* ==========================================================================
   Layout / Categories d'Offres
   ========================================================================== */

// Bloc inro pages offres avec les BU
  .offersIntro {
    @include wrapper('min');
    margin: 35px auto;
    position: relative;
    z-index: 1;

    @include breakpoint(tablet) {
      margin: 75px auto 25px;
    }

    @include breakpoint(tablethor) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-text {
      box-sizing: border-box;
      padding: 0 10px;
      margin-bottom: 20px;

      @include breakpoint(phablet) {
        margin-bottom: 30px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 45%;
        padding: 0;
        padding-right: 3%;
        margin-bottom: 50px;
      }

      @include breakpoint(laptop) {
        flex-basis: 60%;
        padding-right: 10%;
        margin-bottom: 0;
      }

      .title {
        @extend .h1-title;
        width: 100%;
        font-size: 32px;
        word-break: break-word;
        margin-bottom: 15px;
        padding-top: 15px;
        position: relative;
        z-index: 1;

        @include breakpoint(tablet) {
          width: 90%;
          font-size: 40px;
        }

        @include breakpoint(laptop) {
          width: 80%;
          font-size: 50px;
        }

        @include breakpoint(desktop) {
          width: 70%;
        }

        @include breakpoint(mediumdesktop) {
          padding-top: 0;
          margin-bottom: 30px;
        }
      }
    }

    &-bu {
      display: block;
      height: 100%;
      flex-wrap: wrap;
      position: relative;

      @include breakpoint(phablet) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @include breakpoint(tablethor) {
        flex-basis: 55%;
      }

      @include breakpoint(laptop) {
        flex-basis: 40%;
      }

      .offerbu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        text-align: center;
        color: $whiteColor;
        border-radius: 15px;
        padding: 20px 0 10px;
        border: 2px solid $whiteColor;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 10px;

        @include breakpoint(phablet) {
          width: calc(100% / 2);
          flex-basis: calc(100% / 2);
          padding: 35px 0 20px;
          height: 205px;
          margin-bottom: 0;
        }

        &-title {
          max-width: 185px;
          word-break: break-word;
          margin-bottom: 5px;
          padding-top: 5px;
          line-height: 1.3;
          font-family: $special-font-family;
          font-weight: bold;
          letter-spacing: 0.7px;
          text-transform: uppercase;
        }

        &-puzzle {
          display: none;
          width: 30px;
          height: 20px;
          border: 3px solid $whiteColor;
          position: absolute;
          z-index: 1;

          @include breakpoint(phablet) {
            display: block;
          }
        }

        .btn-main-black {
          width: auto;
          margin: 10px 0;
          padding: 11px 26px;
        }

        &:nth-child(1) {
          @include breakpoint(phablet) {
            padding: 10px 0 15px;
          }

          .offerbu-puzzle{
            border-left: 0;
            border-radius: 0 20px 20px 0;
            right: -23px;
            width: 20px;
            height: 30px;
          }
        }

        &:nth-child(2){
          .offerbu-puzzle{
            border-top: 0;
            border-radius: 0 0 20px 20px;
            bottom: -23px;
          }
        }

        &:nth-child(3){
          .offerbu-puzzle {
            border-bottom: 0;
            border-radius: 20px 20px 0 0;
            top: -23px;
          }
        }

        &:nth-child(4){
          .offerbu-puzzle{
            border-right: 0;
            border-radius: 20px 0 0 20px;
            left: -23px;
            width: 20px;
            height: 30px;
          }
        }
      }

      .logo-bucenter {
        display: none;
        align-items: center;
        justify-content: center;
        width: 125px;
        height: 125px;
        transform: translate(-50%, -50%);
        margin-top: 12px;
        position: absolute;
        top: 50%;
        left: 50%;

        @include breakpoint(phablet) {
          display: flex;
        }

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          background-color: $whiteColor;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 50%;
        }

        img{
          width: 90%;
          height: auto;
          position: relative;
          top: 3px;
          z-index: 1;
        }
      }
    }

    &.is-widget {
      margin: 15px auto;

      @include breakpoint(tablet) {
        margin: 25px auto;
      }
    }
  }

// Catégories d'offres
  .categories {
    @include wrapper('min');
    margin: 35px auto;
    position: relative;
    z-index: 1;

    @include breakpoint(tablet) {
      margin: 75px auto 25px;
    }

    &-elem {
      display: block;
      margin-bottom: 25px;

      @include breakpoint(laptop) {
        margin-bottom: 45px;
      }

      @include breakpoint(mediumdesktop) {
        margin-bottom: 75px;
      }

      .title {
        @extend .h1-title;
        font-size: 32px;
        word-break: break-word;
        margin-bottom: 15px;
        padding-top: 15px;
        position: relative;
        z-index: 1;

        @include breakpoint(tablet) {
          font-size: 50px;
        }

        @include breakpoint(tablethor) {
          width: 70%;
        }

        @include breakpoint(laptop) {
          width: 65%;
        }

        @include breakpoint(desktop) {
          width: 55%;
        }

        @include breakpoint(mediumdesktop) {
          width: 50%;
          padding-top: 0;
          margin-bottom: 20px;
        }
      }

      .slogan {
        display: block;
        font-size: 24px;
        font-family: $special-font-family;
        font-weight: 600;
        line-height: 1.1;
        margin-bottom: 25px;
      }

      .wysiwyg {
        width: 100%;
        max-width: 1000px;
        margin: 0;

        & + .btn-main {
          padding: 14px 45px;
          margin: 30px 0;
        }
      }
    }
  }