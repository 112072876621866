
@font-face {
  font-family: 'Brandon Text';
  src: url('./fonts/BrandonText-Black.eot');
  src: url('./fonts/BrandonText-Black.eot?#iefix') format('embedded-opentype'),
  url('./fonts/BrandonText-Black.woff2') format('woff2'),
  url('./fonts/BrandonText-Black.woff') format('woff'),
  url('./fonts/BrandonText-Black.ttf') format('truetype'),
  url('./fonts/BrandonText-Black.svg#BrandonText-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Brandon Text';
  src: url('./fonts/BrandonText-Regular.eot');
  src: url('./fonts/BrandonText-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/BrandonText-Regular.woff2') format('woff2'),
  url('./fonts/BrandonText-Regular.woff') format('woff'),
  url('./fonts/BrandonText-Regular.ttf') format('truetype'),
  url('./fonts/BrandonText-Regular.svg#BrandonText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Brandon Text';
  src: url('./fonts/BrandonText-Bold.eot');
  src: url('./fonts/BrandonText-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/BrandonText-Bold.woff2') format('woff2'),
  url('./fonts/BrandonText-Bold.woff') format('woff'),
  url('./fonts/BrandonText-Bold.ttf') format('truetype'),
  url('./fonts/BrandonText-Bold.svg#BrandonText-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Brandon Text';
  src: url('./fonts/BrandonText-Medium.eot');
  src: url('./fonts/BrandonText-Medium.eot?#iefix') format('embedded-opentype'),
  url('./fonts/BrandonText-Medium.woff2') format('woff2'),
  url('./fonts/BrandonText-Medium.woff') format('woff'),
  url('./fonts/BrandonText-Medium.ttf') format('truetype'),
  url('./fonts/BrandonText-Medium.svg#BrandonText-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}


@font-face {
  font-family: 'Calibri';
  src: url('./fonts/Calibri-Bold.woff2') format('woff2'),
  url('./fonts/Calibri-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Calibri';
  src: url('./fonts/Calibri.woff2') format('woff2'),
  url('./fonts/Calibri.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Diemme Script';
  src: url('./fonts/DiemmeScript.woff2') format('woff2'),
  url('./fonts/DiemmeScript.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
