/* ==========================================================================
   Layout / Menu Navigation
   ========================================================================== */

  .navigation {
    display: block;
    width: 100%;
    position: fixed;
    background: $backgroundLight;
    top: 0;
    left: 0;
    z-index: 5;
    transition: all $durationSmall $easeInOutCirc;

    &-logo {
      display: block;
      background: $whiteColor;
      transition: all $duration $easeOutCubic;
      padding: 15px 20px 0;
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 1;

      @include breakpoint(phablet) {
        padding: 15px 25px 15px;
        left: 25px;
      }

      @include breakpoint(tablet) {
        padding: 50px 35px 5px;
      }

      @include breakpoint(tablethor) {
        left: 35px;
      }

      @include breakpoint(desktop) {
        padding: 50px 50px 5px;
      }

      @include breakpoint(largedesktop) {
        left: 85px;
      }

      img {
        max-width: 130px;
        height: auto;
        transition: all $duration $easeOutCubic;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          max-height: 55px;
        }

        @include breakpoint(415px) {
          max-width: 120px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-height: 50px;
          }
        }

        @include breakpoint(phablet) {
          max-width: 140px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-height: 55px;
          }
        }

        @include breakpoint(tablet) {
          max-width: 205px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-height: 85px;
          }
        }

        @include breakpoint(desktop) {
          max-width: 210px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-height: 95px;
          }
        }
      }
    }

    &-prenav {
      display: block;
      text-align: right;
      transition: all $duration $easeOutCubic;

      @include breakpoint(tablet) {
        padding: 30px 30px;
      }

      @include breakpoint(920px) {
        padding: 35px 40px 25px;
      }

      .links {
        display: none;

        @include breakpoint(tablet) {
          display: inline-block;
          vertical-align: top;
        }

        li {
          display: inline-block;
          list-style-type: none;
          margin-right: 35px;

          &:last-child {
            margin-right: 0;

            @include breakpoint(920px) {
              margin-right: 35px;
            }

            @include breakpoint(tablethor) {
              margin-right: 50px;
            }

            @include breakpoint(laptop) {
              margin-right: 70px;
            }
          }

          @include breakpoint(tablethor) {
            margin-right: 50px;
          }

          @include breakpoint(laptop) {
            margin-right: 70px;
          }

          a {
            display: inline-block;
            color: $blackColor;
            font-family: $special-font-family;
            font-weight: 900;
            font-size: 13px;
            text-decoration: none;

            &:after {
              content: ' ';
              display: block;
              float: right;
              width: 0;
              height: 2px;
              margin-top: 3px;
              background: $yellowColor;
              transition: all $duration $easeInOutCirc;
            }

            &:hover:after {
              //width: 100%;
              color: $yellowColor;
              transition: all $duration $easeInOutCirc;
            }
          }
        }

        &-contact a:before {
           content: ' ';
           display: inline-block;
           width: 22px;
           height: 17px;
           background: url('images/contact3.svg') center center/cover no-repeat;
           margin-right: 15px;
           position: relative;
           top: 5px;
         }
        &-contact a:hover:after {
          width: calc(100% - 36px);
        }

        &-jobs a:before {
          content: ' ';
          display: inline-block;
          width: 18px;
          height: 16px;
          background: url('images/work.svg') center center/cover no-repeat;
          margin-right: 15px;
          position: relative;
          top: 4px;
        }
        &-jobs a:hover:after {
          width: calc(100% - 32px);
        }

        &-doc a:before {
          content: ' ';
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url('images/Documents.svg') center center/cover no-repeat;
          margin-right: 15px;
          position: relative;
          top: 3px;
        }
        &-doc a:hover:after {
          width: calc(100% - 29px);
        }
      }

      .social {
        display: block;
        width: 100%;
        text-align: left;
        background: darken($yellowColor, 2%);
        left: 100%;
        padding: 15px 0 0 23px;
        top: 0;
        position: absolute;
        z-index: 2;

        @include breakpoint(920px) {
          display: inline-block;
          vertical-align: top;
          width: auto;
          left: 0;
          background: transparent;
          padding: 0;
          position: relative;
        }

        li {
          display: inline-block;
          list-style-type: none;
          position: relative;
          bottom: 1px;

          &:not(:last-child) {
            margin-right: 10px;
          }

          a {
            display: block;
            width: 30px;
            height: 30px;
            transition: all $duration $easeInOutQuart;

            svg {
              transition: all $duration $easeInOutQuart;
            }

            &:hover svg {
              @include breakpoint(laptop) {
                fill: $yellowColor;
                transition: all $duration $easeInOutQuart;
              }
            }
          }
        }
      }
    }

    &-menu {
      display: block;
      width: 100%;
      background: $yellowColor;
      text-align: right;
      position: relative;

      .menu {
        display: none;
        width: auto;
        top: 0;
        right: 0;
        height: 100%;
        position: relative;
        text-align: center;
        z-index: 1;

        @include breakpoint(laptop) {
          display: inline-flex;
        }

        li {
          display: block;
          list-style-type: none;
          float: left;
          position: relative;

          a {
            display: block;
            font-size: 12px;
            color: $blackColor;
            text-decoration: none;
            font-family: $special-font-family;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.2;
            padding: 25px 90px 25px 50px;
            border-bottom: 1px solid darken($yellowColor, 5%);;
            position: relative;

            @-moz-document url-prefix() {
              line-height: 1.1;
            }

            @include breakpoint(laptop) {
              width: auto;
              padding: 20px 25px;
              border: 0;
            }

            @include breakpoint(desktop) {
              padding: 20px 30px;
            }

            @include breakpoint(largedesktop) {
              padding: 20px 40px;
            }

            &:after {
              content: ' ';
              display: block;
              width: 0;
              height: 3px;
              background: $blackColor;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
            }
          }

          &.home {
            margin-top: 55px;

            @include breakpoint(920px) {
              margin-top: 15px;
            }

            @include breakpoint(laptop) {
              width: 55px;
              margin-top: 0;
              margin-right: 20px;
            }

            @include breakpoint(largedesktop) {
              width: auto;
              margin-right: 0;
            }

            a {
              display: block;
              background: url('./images/Home.svg') 45px 22px/ 16px 18px no-repeat;
              padding: 25px 20px 25px 75px;
              position: relative;

              @include breakpoint(laptop) {
                width: 18px;
                height: 23px;
                text-indent: -999999px;
                background: url('./images/Home.svg') center center/ 18px 20px no-repeat;
                padding: 15px 35px 17px;
              }

              @include breakpoint(largedesktop) {
                text-indent: 0;
                width: auto;
                height: auto;
                background: url('./images/Home.svg') 35px 17px/15px 17px no-repeat;
                padding: 20px 40px 20px 65px;
              }
            }

            &:not(.current-menu-item) a:hover {
              background: $blackColor url('images/Home-blanc.svg') center center/ 18px 20px no-repeat;

              @include breakpoint(largedesktop) {
                background: $blackColor url('images/Home-blanc.svg') 21px 17px/15px 17px no-repeat;
              }
            }
          }

          > ul {
            display: none;
            position: absolute;
            border: 4px solid $yellowColor;
            box-shadow: 3px 5px 10px 0 rgba(0,0,0,0.2);
            border-top: 0;
            overflow: hidden;
            width: 130%;
            z-index: 2;

            @include breakpoint(mediumdesktop) {
              width: 120%;
            }

            li {
              display: block;
              width: 100%;
              background: $whiteColor;
              border-bottom: 1px solid #e0e0e0;
              text-align: left;

              a:after { display: none; }

              &.current-menu-item > a {
                background: $yellowColor;
              }

              .openSubmenu {
                top: 7px;
                right: 5px;
              }
            }

            > ul {
              li {

              }
            }
          }

          &.menu-item-1701 > ul {
            width: 165%;

            @include breakpoint(mediumdesktop) {
              width: 145%;
            }
          }

          &.current-menu-item > a {
            color: $whiteColor;

            @include breakpoint(laptop) {
              color: inherit;
            }
          }

          @include breakpoint(laptop) {
            &.current-menu-item a:after {
              width: 60%;
            }

            &:not(.current-menu-item) a:hover {
              color: $whiteColor;
              background: $blackColor;
            }
          }

          &.onlyMobile {
            display: block;

            @include breakpoint(tablet) {
              display: none;
            }
          }

          // MEGA MENU OFFRES
          &.offersmegamenu {
            &:hover a + ul {
              height: inherit;
              max-height: 400px;
              transition: all $durationSmall $easeInOutCirc;
            }

            @include breakpoint(laptop) {
              > ul {
                display: flex!important;
                width: 882px;
                background: $whiteColor;
                max-height: 0;
                padding: 0 10px;
                border: 0;
                transition: all $durationSmall $easeInOutCirc;
                box-sizing: border-box;
                left: -14em;
                top: 100%;

                &:before {
                  content: ' ';
                  display: block;
                  width: calc(100% - 8px);
                  height: calc(100% - 4px);
                  border: 4px solid $yellowColor;
                  border-top: 0;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 0;
                }

                @include breakpoint(desktop) {
                  width: 950px;
                  left: -17em;
                }

                @include breakpoint(mediumdesktop) {
                  left: -22em;
                }

                @include breakpoint(largedesktop) {
                  left: -24.95em;
                }

                li {
                  display: block;
                  width: 25%;
                  background: transparent;
                  border: 0;
                  padding: 5px;
                  margin: 25px 0;
                  box-sizing: border-box;
                  box-shadow: 0 0 0 0 transparent;

                  > a {
                    font-size: 12px;
                    font-weight: 900;
                    max-width: 160px;
                    padding: 0;
                    margin-bottom: 0;
                    position: relative;
                    top: -10px;
                    left: 15px;

                    &:hover {
                      color: $blackColor;
                      background: transparent;
                    }
                  }

                  &.current-menu-item a {
                    background: transparent;
                  }

                  > ul {
                    display: block!important;
                    width: 100%;
                    position: relative;
                    border: 0;
                    box-shadow: 0 0 0 0 transparent;

                    &:before {
                      display: none;
                    }

                    li {
                      display: block;
                      width: 100%;
                      border: 0!important;
                      padding: 1px 0 0;
                      margin: 0;

                      a {
                        display: flex;
                        align-items: center;
                        text-transform: none;
                        width: 100%;
                        font-size: 13px;
                        max-width: inherit;
                        font-family: $alternativeFont;
                        font-weight: normal;
                        line-height: normal;
                        letter-spacing: 0.4px;
                        box-sizing: border-box;
                        padding: 12px 25px 12px 15px;
                        transition: all $duration $easeInOutCirc;
                        top: 0;
                        left: 0;
                        margin: 0;

                        &:hover {
                          color: $whiteColor;
                          transition: all $duration $easeInOutCirc;

                          .hoverBack {
                            opacity: 1.0;
                            transition: all $duration $easeInOutCirc;
                          }
                        }

                        .icon {
                          display: flex;
                          width: 35px;
                          height: 35px;
                          border-radius: 50%;
                          padding: 5px;
                          box-sizing: border-box;
                          align-items: center;
                          justify-content: center;
                          position: relative;
                          margin-right: 10px;
                          z-index: 1;

                          img {
                            width: 26px;
                            height: 26px;
                          }
                        }

                        .title-item {
                          position: relative;
                          bottom: 2px;
                          z-index: 1;
                        }

                        .hoverBack {
                          display: block;
                          width: 100%;
                          height: 100%;
                          opacity: 0;
                          transition: all $duration $easeInOutCirc;
                          position: absolute;
                          top: 0;
                          left: 0;
                          z-index: 0;
                        }
                      }

                      &.current-menu-item a {
                        color: $whiteColor;
                        font-weight: 600;
                        cursor: default;

                        .hoverBack {
                          opacity: 1.0;
                        }
                      }
                    }
                  }

                  &:not(:last-child) {
                    border-right: 1px solid #e0e0e0;
                  }

                  &:nth-child(3) > ul > li.menu-item-3074 > a, &:nth-child(3) > ul > li.menu-item-3902 > a {
                    padding-right: 20px;
                  }

                  &:last-child > ul > li {
                    padding: 0;

                    a {
                      padding: 10px 35px 10px 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .client {
        display: inline-block;
        vertical-align: top;
        color: $whiteColor;
        background: $blackColor;
        text-decoration: none;
        font-family: $special-font-family;
        font-weight: 700;
        font-size: 0;
        text-transform: uppercase;
        line-height: 1;
        padding: 20px 15px 35px 40px;
        position: relative;

        @include breakpoint(415px) {
          padding: 20px 15px 20px 50px;
          font-size: 14px;
          line-height: 1;
        }

        @include breakpoint(phablet) {
          padding: 20px 35px 20px 71px;
        }

        &:hover {
          color: $yellowColor;
        }

        &:before {
          content: ' ';
          display: inline-block;
          width: 16px;
          height: 22px;
          background: url('images/people.svg') center center/ 16px 22px no-repeat;
          position: absolute;
          left: 20px;
          top: 16px;

          @include breakpoint(phablet) {
            left: 37px;
          }
        }
      }
    }

    &.sticky {
      box-shadow: -6px 1px 15px 0 rgba(0,0,0,0.3);

      .navigation-logo {
        transition: all $duration $easeOutCubic;
        padding: 5px 10px 0;
        width: auto;
        height: calc(100% - 5px);
        left: 0;

        @-moz-document url-prefix() {
          padding: 5px 0 5px 20px;
          width: 120px;
        }

        @include breakpoint(tablet) {
          padding: 5px 5px 0 5px;
          height: 90%;
          left: 25px;
        }

        @include breakpoint(laptop) {
          padding: 10px 10px 0 10px;
          height: calc(100% - 10px);

          @-moz-document url-prefix() {
            padding: 14px 0 14px 30px;
            width: 210px;
          }
        }

        @include breakpoint(1600px) {
          padding: 10px 10px 0;
          height: 92%;
          @-moz-document url-prefix() { padding: 18px 10px 12px 35px; width: 220px; }
        }

        img {
          display: block;
          width: auto;
          max-height: 90%;
          margin: auto;
          transition: all $duration $easeOutCubic;
          min-width: 90px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: 95px;
          }

          @include breakpoint(laptop) {
            min-width: 180px;
          }
        }
      }

      .navigation-prenav {
        height: 0;
        padding: 0;
        overflow: hidden;
        transition: all $duration $easeOutCubic;

        @include breakpoint(laptop) {
          padding: 13px 40px 7px;
          overflow: inherit;
          height: auto;
        }

        @include breakpoint(1600px) {
          padding: 18px 40px 13px;
        }
      }

      & + .burger {
        transition: all $duration $easeOutCubic;

        @include breakpoint(tablet) {
          top: 14px;

          &.active {
            top: 20px;
          }
        }

        @include breakpoint(laptop) {
          top: 70px;
        }
      }
    }
  }

  // RESPONSIVE DESIGN MENU //
  .burger {
    width: 35px;
    height: 26px;
    position: fixed;
    top: 14px;
    right: 75px;
    padding-left: 65px;
    transition: all $duration $easeOutCubic;
    margin: auto;
    cursor: pointer;
    z-index: 6;

    @include breakpoint(415px) {
      right: 185px;
    }

    @include breakpoint(tablet) {
      top: 97px;
    }

    @include breakpoint(phablet) {
      right: 230px;
    }

    @include breakpoint(920px) {
      top: 110px;
    }

    &:after {
      content: 'Menu';
      display: none;
      font-family: $special-font-family;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.02rem;
      text-transform: uppercase;
      transition: all $duration $easeInOutQuart;
      line-height: 1.7;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;

      @include breakpoint(phablet) {
        display: block;
      }
    }

    @include breakpoint(laptop) {
      display: none;
    }

    &.active {
      width: 25px;
      height: 16px;
      right: 5%;
      top: 20px;
      bottom: inherit;

      @include breakpoint(phablet) {
        right: 2%;
      }

      @include breakpoint(tablet) {
        right: 2%;
      }

      &:after {
        content: 'Fermer';
        display: block;
        font-size: 14px;
        line-height: 1.3;
        left: 0;
        transition: all $duration $easeInOutQuart;
      }

      .burger-line {
        height: 3px;
        border-radius: 12px;
        transition: all $durationSmall $easeOutCubic;

        &:first-child {
          transform: rotate(45deg);
          transform-origin: left;
          position: relative;
          top: -2px;
          left: 0;
        }

        &:nth-child(2) {
          display: none;
        }

        &:last-child {
          transform: rotate(-45deg);
          transform-origin: left;
          position: relative;
          top: 6px;
          left: 0;
        }
      }
    }

    &-line {
      width: 100%;
      height: 4px;
      background-color: $blackColor;
      margin: 0 auto;
      margin-bottom: 7px;
      transition: all $durationSmall $easeOutCubic;
    }
  }

  .navresponsive {
    display: none;
    width: 85%;
    position: fixed;
    background: darken($yellowColor, 2%);
    top: 0;
    right: 0;
    height: 100vh;
    overflow: auto;
    text-align: left;
    z-index: 2;
    transition: all $durationIntermediate $easeOutCubic;

    @include breakpoint(phablet) {
      width: auto;
    }

    @include breakpoint(laptop) {
      display: none;
    }

    li {
      display: block;
      list-style-type: none;
      position: relative;

      .openSubmenu {
        content: ' ';
        display: block;
        width: 8px;
        height: 10px;
        background: url(images/arrow.svg) center center/8px 8px no-repeat;
        transition: all $duration $easeInOutCirc;
        text-indent: -99999px;
        padding: 20px;
        position: absolute;
        top: 12px;
        right: 15px;
        margin: auto;
        z-index: 2;

        &.active {
          transform: rotate(90deg);
          transition: all $duration $easeInOutCirc;
        }
      }

      > ul {
        display: none;
        border-bottom: 2px solid rgb(204, 176, 0);

        li {
          .openSubmenu{
            top: 7px;
          }

          > ul {
            li{
              width: auto;

              @include breakpoint(phablet) {
                width: 290px;
              }

              a{
                padding-left: 50px;

                &:before{
                  content: '';
                }
              }
            }
          }
        }

        li > a {
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.01rem;
          padding: 20px 30px 20px 45px;
          display: flex;
          align-items: center;

          &:before {
            content: '- ';
          }

          .icon{
            margin-right: 12px;
            display: flex;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            padding: 5px;
            align-items: center;
            justify-content: center;
          }
        }
      }

      a {
        display: block;
        font-size: 12px;
        color: $blackColor;
        text-decoration: none;
        font-family: $special-font-family;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.2;
        padding: 25px 90px 25px 40px;
        border-bottom: 1px solid darken($yellowColor, 5%);;
        position: relative;

        @include breakpoint(phablet) {
          padding: 25px 120px 25px 40px;
        }

        &:after {
          content: ' ';
          display: block;
          width: 0;
          height: 3px;
          background: $blackColor;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &.home {
        margin-top: 55px;

        @include breakpoint(920px) {
          margin-top: 15px;
        }

        a {
          display: block;
          background: url('images/Home.svg') 40px 22px/ 16px 18px no-repeat;
          padding: 25px 20px 25px 70px;
          position: relative;

          &:after {
            bottom: -3px;
          }
        }
      }

      &.current-menu-item > a {
        color: $whiteColor;
      }

      &.onlyMobile {
        display: block;

        @include breakpoint(laptop) {
          display: none;
        }
      }
    }
  }

  .fadebg {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -150px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all $duration linear;
    z-index: 0;
    visibility: hidden;

    @include breakpoint(laptop) {
      display: none;
    }

    &.show {
      opacity: 1.0;
      z-index: 10;
      transition: all $duration linear;
    }
  }
