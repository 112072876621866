/* ==========================================================================
   Layout / Bloc texte avec chiffres clés
   ========================================================================== */

  .keynumberstxt {
    @include wrapper();

    @include breakpoint(tablet) {
      margin: 30px auto 5px;
    }

    @include breakpoint(tablethor) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 45px auto 25px;
    }

    @include breakpoint(desktop) {
      margin: 45px auto;
      justify-content: inherit;
      align-items: inherit;
    }

    &.right {
      @include breakpoint(tablethor) {
        align-items: center;
      }
    }

    &-text {
      display: block;
      width: 100%;
      margin-bottom: 35px;

      @include breakpoint(tablet) {
        margin-bottom: 45px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 46%;
        margin-right: 2%;
        margin-bottom: 0;
        order: 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 43%;
        margin-right: 0;
      }

      @include breakpoint(desktop) {
        flex-basis: 48%;
      }

      @include breakpoint(mediumdesktop) {
        flex-basis: 50%;
      }

      &.right {
        order: 1;

        @include breakpoint(tablethor) {
          flex-basis: 50%;
          margin-left: 0;
          margin-right: 0;
        }

        @include breakpoint(laptop) {
          flex-basis: 44%;
          margin-right: 5%;
          margin-left: 5%;
        }

        @include breakpoint(desktop) {
          flex-basis: 38%;
          margin-left: 0;
          margin-right: 10%;
        }

        & + .keynumberstxt-numbers {
          margin-right: 0;

          @include breakpoint(tablethor) {
            flex-basis: 55%;
            position: relative;
            right: 4%;
          }

          @include breakpoint(laptop) {
            flex-basis: 45%;
            right: 0;
          }

          @include breakpoint(laptop) {
            flex-basis: 52%;
          }
        }
      }

      .textimg {
        width: 90%;

        @include breakpoint(tablet) {
          width: 90%!important;
        }

        @include breakpoint(tablethor) {
          width: 87%;
        }
      }

      .textimg-text {
        @include breakpoint(tablethor) {
          width: auto;
          padding: 45px 50px;
        }

        @include breakpoint(laptop) {
          padding: 45px 50px 50px;
        }
      }
    }

    &-numbers {
      @include breakpoint(tablethor) {
        flex-basis: 52%;
        order: 0;
      }

      @include breakpoint(laptop) {
        flex-basis: 57%;
      }

      @include breakpoint(desktop) {
        margin-right: 3%;
      }

      @include breakpoint(mediumdesktop) {
        margin-right: 0;
      }

      .blockeysnumber {
        @include breakpoint(800px) {
          width: 100%;
        }
      }

      .keynumber {
        margin: 20px 5% 30px;

        @include breakpoint(tablet) {
          margin: 20px 7% 30px;

          &:nth-last-child(2) {
            margin-bottom: 0;
          }

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }

        @include breakpoint(800px) {
          margin: 20px 3% 40px;
        }

        @include breakpoint(910px) {
          margin: 20px 4% 40px;
        }

        @include breakpoint(tablethor) {
          margin: 20px 3% 40px;
        }

        @include breakpoint(laptop) {
          margin: 5px 6% 50px;
        }
      }
    }
  }