/* ==========================================================================
   Components / Card Offer
   ========================================================================== */

  .cardoffer {
    max-height: 345px;
    overflow: hidden;
    list-style-type: none;
    position: relative;
    margin-bottom: 10px;

    @include breakpoint(phablet) {
      max-height: inherit;
    }

    @include breakpoint(tablet) {
      max-height: 400px;
    }

    @include breakpoint(laptop) {
      max-height: inherit;
    }

    &:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      background: $blackColor;
      transition: all $duration $easeInOutCubic;
      opacity: 0.2;
      position: absolute;
      z-index: 0;
    }

    &.fullWidth {
      background-size: cover;
      background-position: center center;
      width: 100%;
      flex-basis: 99.5%;
      min-height: 345px;
      height: auto;

      @include breakpoint(phablet) {
        border-radius: 10px;
        min-height: auto;
      }

      @include breakpoint(laptop) {
        border-radius: 20px;
      }

      .cardoffer-content {
        display: table;
        width: calc(100% - 25px);
        height: auto;
        min-height: auto;
        padding: 35px 0;
        margin: 10px auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;

        @include breakpoint(mobileonly) {
          display: block;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            left: 15px;
          }
        }

        @include breakpoint(phablet) {
          width: auto;
          margin: 40px 0;
          padding: 55px 20px;
          max-width: 285px;
          float: left;
          position: relative;
          margin-left: 20px;
          bottom: 0;
        }

        @include breakpoint(tablet) {
          max-width: 300px;
          padding: 65px 20px;
          margin: 60px 0;
          margin-left: 40px;
        }

        @include breakpoint(tablethor) {
          max-width: 350px;
          padding: 65px 50px;
        }

        .btn-main-black {
          display: table;
          padding: 11px 35px;
          width: auto;
        }
      }

      .cardoffer-picto {
        display: block;
        max-height: inherit;
        width: 85px;
        height: 85px;
        object-fit: inherit;
        position: relative;
        top: 0;
        margin: 30px auto 0;

        @include breakpoint(phablet) {
          width: 125px;
          height: 125px;
          position: absolute;
          bottom: 0;
          right: 7%;
          left: auto;
          margin: auto;
        }

        @include breakpoint(tablet) {
          width: 165px;
          height: 165px;
          right: 11%;
        }
      }
    }

    img:not(.cardoffer-picto) {
      display: block;
      width: 100%;
      min-height: 100%;
      height: auto;
      object-fit: cover;
    }

    &-picto {
      display: block;
      height: auto;
      max-height: 85px;
      object-fit: inherit;
      top: 15%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      border-radius: 15px;
      min-height: 140px;
      padding: 25px 0 20px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      margin: auto;
      z-index: 2;
    }
    a.cardoffer-content:hover {
      bottom: 20px;
    }

    &-title {
      display: block;
      width: calc(100% - 40px);
      min-height: 3rem;
      font-family: $special-font-family;
      font-size: 20px;
      font-weight: 900;
      line-height: 1.25;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
      padding: 0 20px;
      color: $whiteColor;

      @include breakpoint(tablet) {
        width: calc(100% - 50px);
        padding: 0 25px;

        &.longtitle {
          padding: 0 10px;
          width: calc(100% - 20px);
        }
      }
    }
  }