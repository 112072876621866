/* ==========================================================================
   Components / Breadcrumb - Page Navigation
     ========================================================================== */

  .breadcrumb {
    display: block;
    width: 90%;
    margin: auto;
    font-size: 14px;
    font-family: $special-font-family;
    color: $whiteColor;
    position: relative;
    top: 75px;
    z-index: 3;

    @include breakpoint(phablet) {
      width: 85%;
      font-size: 15px;
      top: 80px;
      letter-spacing: 0.01rem;
    }

    @include breakpoint(tablet) {
      top: 140px;
    }

    b, ul {
      display: inline-block;
    }

    ul li {
      display: inline-block;
      margin-bottom: 2px;

      &:last-child {
        font-weight: 700;
      }

      &:not(:last-child) {
        &:after {
          content: ' ';
          display: inline-block;
          width: 7px;
          height: 10px;
          margin: 0 5px 0 7px;
          background: url(images/arrow-yellow.svg) center center/10px 10px no-repeat;
        }
      }

      a {
        display: inline-block;
        color: $whiteColor;
        position: relative;

        &:after {
          content: ' ';
          display: block;
          width: 0;
          height: 1px;
          background: $whiteColor;
          opacity: 0.5;
          transition: all $duration $easeInOutCirc;
          position: absolute;
          left: 1px;
          bottom: -1px;
        }

        @include breakpoint(laptop) {
          &:hover {
            &:after {
              width: 98%;
              transition: all $duration $easeInOutCirc;
            }
          }
        }
      }
    }
  }