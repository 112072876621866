/* ==========================================================================
 Layout / Slider Blocs Marques
 ========================================================================== */

  .sliderbrand {
    display: block;
    width: 100%;
    text-align: center;
    padding: 45px 0;
    position: relative;
    z-index: 2;
    margin: 35px 0 15px;

    @include breakpoint(phablet) {
      margin: 35px 0;
    }

    @include breakpoint(tablet) {
      margin: 65px 0 35px;
    }

    @include breakpoint(tablethor) {
      padding: 85px 0;
      margin: 45px 0;
    }

    @include breakpoint(laptop) {
      padding: 85px 0;
      margin: 0 0 50px;
    }

    @include breakpoint(desktop) {
      padding: 105px 0;
      margin: 0 0 75px;
    }

    &.bg-yellow {  background: $yellowColor;  }
    &.bg-grey {  background: $greyLightBack;  }

    .wrapper {
      @include wrapper();
      padding: 0 10px;
    }

    .h1-title {
      font-size: 30px;

      @include breakpoint(tablet) {
        font-size: 40px;
      }
    }

    .subtitle {
      font-family: $alternativeFont;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.02rem;

      @include breakpoint(tablet) {
        font-size: 22px;
      }

      @include breakpoint(laptop) {
        width: 85%;
        margin: auto;
      }
    }

    .slider {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 90%;
      text-align: center;
      margin: 35px auto;

      &-elem {
        padding: 0 15px;
        box-sizing: border-box;

        a:hover img {
          transform: scale(0.9);
          transition: all $duration $easeInOutQuart;
        }

        img {
          width: 100%;
          height: auto;
          transition: all $duration $easeInOutQuart;
        }
      }

      .slick-prev, .slick-next {
        display: block;
        text-indent: -99999px;
        width: 20px;
        height: 30px;
        padding: 0 10px;
        position: relative;
        transition: all $durationSmall $easeInOutQuint;

        &:after {
          content: ' ';
          display: block;
          width: 15px;
          height: 25px;
          background: url('images/arrow.svg') center center/ 15px 25px no-repeat;
          position: absolute;
          top: 0;
          left: 0;

          @include breakpoint(tablethor) {
            width: 20px;
            height: 30px;
            background: url('images/arrow.svg') center center/ 20px 30px no-repeat;
          }
        }
      }

      .slick-next {
        right: -5px;

        @include breakpoint(phablet) {
          right: -15px;
        }

        @include breakpoint(laptop) {
          right: -30px;

          &:hover {
            right: -35px;
            transition: all $durationSmall $easeInOutQuint;
          }
        }
      }

      .slick-prev {
        left: -5px;
        transform: rotate(180deg);

        @include breakpoint(phablet) {
          left: -15px;
        }

        @include breakpoint(laptop) {
          left: -30px;

          &:hover {
            left: -35px;
            transition: all $durationSmall $easeInOutQuint;
          }
        }
      }
    }

    .btn {
      margin: 0 auto;
    }
  }

