/* ==========================================================================
 Layout / Bloc Cartes des Produits
 ========================================================================== */

  .offers {
    @include wrapper();
    width: 100%!important;
    margin-top: 15px;
    margin-bottom: 25px;

    @include breakpoint(tablet) {
      margin-top: 25px;
      //margin-bottom: 85px;
    }

    @include breakpoint(desktop) {
      margin-top: 45px;
    }

    &-container {
        display: block;

      @include breakpoint(phablet) {
        @include flex-row();
        justify-content: space-around;
      }

      @include breakpoint(tablet) {
        justify-content: flex-start;
      }

      &.pushblockinside {
        .cardoffer {
          order: 2;

          &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
            order: 0;
          }

          &:nth-child(7) {
            @include breakpoint(phablet) {
              order: 0;
            }

            @include breakpoint(laptop) {
              order: 2;
            }
          }

          &:nth-child(8n) {
            @include breakpoint(phablet) {
              margin-right: 10px;
            }
          }

          &:nth-child(10n), &:nth-child(10n + 4) {
            @include breakpoint(laptop) {
              margin-right: 0;
            }
          }

          &:nth-child(8n), &:nth-child(8n + 5), &:nth-child(12) {
            @include breakpoint(laptop) {
              margin-right: 10px;
            }
          }

          &:nth-child(9) {
            @include breakpoint(phablet) {
              margin-right: 0;
            }

            @include breakpoint(tablethor) {
              margin-right: 10px;
            }
          }

          &:nth-child(10) {
            @include breakpoint(phablet) {
              margin-right: 10px;
            }

            @include breakpoint(tablethor) {
              margin-right: 0;
            }
          }

          &:nth-child(11) {
            @include breakpoint(phablet) {
              margin-right: 0;
            }

            @include breakpoint(tablethor) {
              margin-right: 10px;
            }
          }

          &:nth-child(9), &:nth-child(11) {
            @include breakpoint(tablethor) {
              margin-right: 10px;
            }
          }

          &:nth-child(12) {
            @include breakpoint(phablet) {
              margin-right: 10px;
            }
          }

          &:nth-child(13) {
            @include breakpoint(phablet) {
              margin-right: 0;
            }

            @include breakpoint(laptop) {
              margin-right: 10px;
            }
          }
        }
        .pushproduct {
          order: 1;
        }
      }

      .cardoffer {
        width: 100%;

        @include breakpoint(phablet) {
          width: 49%;
          margin-right: 10px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @include breakpoint(tablet) {
          width: 49.25%;
          margin-right: 10px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @include breakpoint(tablethor) {
          width: 32.55%;

          &:nth-child(2n) {
            margin-right: 10px;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @include breakpoint(laptop) {
          width: 24.2%;

          &:nth-child(3n) {
            margin-right: 10px;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }
        }

        @include breakpoint(desktop) {
          width: 24.3%;

          &:nth-child(3n) {
            margin-right: 10px;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }
    }

    .h2-title {
      @include breakpoint(tablet) {
        width: 80%;
        margin: auto auto 40px;
      }

      @include breakpoint(tablethor) {
        width: auto;
        margin: auto auto 60px;
      }
    }
  }
