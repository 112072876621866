/* ==========================================================================
   Components / Title
   ========================================================================== */

  .h1-title {
    font-family: $special-font-family;
    color: $blackColor;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include breakpoint(tablet) {
      font-size: 50px;
      line-height: 1.14;
    }
  }

  .h1-title-white {
    @extend .h1-title;
    color: $whiteColor;
  }

  .h2-title {
    font-family: $special-font-family;
    font-size: 28px;
    line-height: 1;
    letter-spacing: 1.1px;
    font-weight: 700;
    margin-bottom: 30px;

    @include breakpoint(tablet) {
      margin-bottom: 60px;
      font-size: 34px;
      line-height: 1.09;
    }
  }

  .h3-title {
    font-family: $special-font-family;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 10px;

    @include breakpoint(tablet) {
      font-size: 25px;
      line-height: 1.2;
    }
  }

  .h4-title {

  }