/* ==========================================================================
   Page / Actualités
   ========================================================================== */

  .newspage {
    .newsblock {
      &-intro {
        margin: 0 0 35px;

        @include breakpoint(tablethor) {
          width: 95%;
          font-size: 20px;
          line-height: 1.3;
        }

        @include breakpoint(laptop) {
          width: 80%;

        }
      }

      &-news {
        @include breakpoint(tablethor) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .newscard {
          @include breakpoint(tablethor) {
            flex-basis: 30.1%;

            &:nth-child(3n) {
              margin-right: 0;
            }
          }

          @include breakpoint(laptop) {
            flex-basis: 22.1%;

            &:nth-child(3n) {
              margin-right: 12px;
            }

            &:nth-child(4n) {
              margin-right: 0;
            }
          }

          @include breakpoint(desktop) {
            flex-basis: 22.5%;
          }

          @include breakpoint(mediumdesktop) {
            flex-basis: 22.7%;
          }
        }
      }
    }
  }