/* ==========================================================================
   Layout / Bloc d'introduction de page
   ========================================================================== */

  .blockintro {
    @include wrapper();
    @extend .h3-title;
    font-weight: 700;
    line-height: 1.24;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @include breakpoint(laptop) {
      margin: 5px auto 15px;
    }

    &-text {
      @include breakpoint(phablet) {
        width: 95%;
      }

      @include breakpoint(laptop) {
        width: 85%;
      }

      @include breakpoint(desktop) {
        width: 80%;
      }

      @include breakpoint(mediumdesktop) {
        width: 78%;
      }
    }
  }

  .widget_block-intro-widget + .widget_sow-editor {
    .so-widget-sow-editor-base {
      @include wrapper('max');

      .textwidget {
        margin: 0;
      }
    }
  }