/* ==========================================================================
   Pages / Site Map
   ========================================================================== */

  .sitemap {
    @include wrapper('min');
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    margin-top: 55px;

    @include breakpoint(phablet) {
      flex-direction: row;
      width: 85%;
    }

    @include breakpoint(tablet) {
      margin-top: 0;
    }

    @include breakpoint(laptop) {
      width: 75%;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
    }

    &-elem {
      margin-right: 5%;
      flex-basis: 45%;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        float: left;

        @include breakpoint(medium) {
          width: 45%;
        }
      }

      ul {
        margin-bottom: 35px;
        margin-left: 15px;
      }

      li {
        list-style-type: circle;
        font-size: 16px;
        margin-left: 5px;
        margin-bottom: 5px;

        > ul {
          margin: 15px 0 15px 15px;

          li {
            list-style-type: none;
            position: relative;
            padding-left: 20px;

            &:before {
              content: ' ';
              font-size: 18px;
              display: block;
              width: 5px;
              height: 5px;
              border: 1px solid $yellowColor;
              border-radius: 500px;
              position: absolute;
              top: 7px;
              left: 0;
            }
          }

          li a {
            font-size: 15px;
            font-weight: 300;
            color: lighten($blackColor, 45%);
          }
        }
      }

      a {
        font-size: 16px;
        font-weight: 400;
        color: $blackColor;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h2 {
      @extend .h3-title;
      margin-bottom: 20px;

      @include breakpoint(tablet) {
        margin-top: 45px;
      }
    }
  }