  /*-- RESET ALL --*/
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, font, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-weight:inherit;
    font-style:inherit;
    font-size:100%;
    font-family:inherit;
    vertical-align:baseline;
  }

  html {
    overflow-x: hidden;
  }

  body {
    display: block;
    width: 100%;
    min-height: 100vh;
    color: $blackColor;
    background-color: $backgroundLight;
    font-family: $font-stack-common;
    font-size: $base-font-size;
    font-weight: 300;
    line-height: $line-height;
    overflow-x: hidden;
  }

  .bodycontent {
    background: $backgroundLight;
    transition: all $durationSmall $easeInOutCirc;
    right: 0;
    left: auto;
    position: relative;
    z-index: 3;
  }

  b, strong {
    font-weight: 700;
  }

  em, i {
    font-style: italic;
  }

  small {
    font-size: 13px;
  }

  a {
    text-decoration: none;
    transition: all $duration $easeInOutCirc;

    &:hover {
      transition: all $duration $easeInOutCirc;
    }
  }

  ul li {
    list-style-type: none;
  }

  .sr-only {
     border: 0 !important;
     clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
     clip-path: inset(50%) !important; /* 2 */
     height: 1px !important;
     overflow: hidden !important;
     padding: 0 !important;
     position: absolute !important;
     width: 1px !important;
     white-space: nowrap !important; /* 3 */
  }

  p small {
    font-size: 13px;
    line-height: 18px;
    display: block;
  }

  .grecaptcha-badge {
    display: none!important;
  }

  #matomo-opt-out label {
    position: relative;
  }

  #matomo-opt-out input[type="checkbox"]:checked+label:after {
    //left: inherit;
  }
