/* ==========================================================================
   Layout / Bloc Vidéo avec Image de couverture
   ========================================================================== */

  .videoblock {
    @include wrapper();
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    @include breakpoint(phablet) {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
    }

    @include breakpoint(tablet) {
      width: 100%;
    }

    @include breakpoint(tablethor) {
      width: 95%;
      margin-bottom: 45px;
    }

    @include breakpoint(laptop) {
      width: 92%;
      margin-bottom: 45px;

      &:not(.showVideo) {
        &:hover {
          .videoblock-image:before {
            opacity: 0.1;
            transition: all $duration $easeInOutCubic;
          }
          .videoblock-title {
            transform: translate(-50%, 15px);
            transition: all $duration $easeInOutCubic;
          }
          img {
            transform: scale(1.03);
            transition: all $duration $easeInOutCubic;
          }
        }
      }
    }

    @include breakpoint(desktop) {
      margin-top: 65px;
      margin-bottom: 85px;
    }

    &.showVideo {
      .videoblock-title {
        transform: translateY(150%);
        transition: all $duration $easeInOutCubic;

        @include breakpoint(tablethor) {
          transform: translateY(100%) translateX(-50%);
        }
      }
    }

    &-image {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background: $blackColor;
        transition: all $duration $easeInOutCubic;
        opacity: 0.4;
        position: absolute;
        z-index: 2;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        transition: all $duration $easeInOutCubic;
        position: relative;
        z-index: 1;
      }
    }

    &-title {
      display: table;
      width: calc(100% - 60px);
      font-family: $special-font-family;
      font-size: 33px;
      font-weight: bold;
      line-height: 1.14;
      letter-spacing: 1px;
      color: $blackColor;
      text-transform: uppercase;
      transition: all $duration $easeInOutCubic;
      background: $yellowColor;
      text-align: center;
      padding: 30px;
      z-index: 4;

      @include breakpoint(phablet) {
        width: 35%;
        padding: 40px 30px 20px;
        position: absolute;
        left: 5%;
        bottom: 0;
      }

      @include breakpoint(tablet) {
        font-size: 40px;
        padding: 30px 40px;
        top: 20%;
      }

      @include breakpoint(tablethor) {
        font-size: 50px;
        width: auto;
        top: inherit;
        left: 50%;
        transform: translateX(-50%);
        padding: 60px 60px 40px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          left: 27%;
          right: 0;
          margin: 0 23%;
        }
      }

      @include breakpoint(desktop) {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          margin: 0 29%;
          left: 21%;
        }
      }

      &:after {
        content: ' ';
        display: block;
        width: 45px;
        height: 45px;
        background: url('images/play.svg') center center / 45px 45px no-repeat;
        margin: 15px auto 0;

        @include breakpoint(tablet) {
          width: 65px;
          height: 65px;
          background: url('images/play.svg') center center / 65px 65px no-repeat;
          margin: 30px auto 0;
        }
      }
    }

    &-iframe {
      display: block;
      width: 100%!important;
      height: 100%!important;
      position: absolute;
      background: rgba(0, 0, 0, 0.9);
      top: 100%;
      left: 0;
      z-index: 4;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }