/* ==========================================================================
   Page / Homepage
   ========================================================================== */

  .homepage {
    .header {
      @include breakpoint(tablet) {
        min-height: 600px;
      }

      @include breakpoint(laptop) {
        min-height: 555px;
      }

      @include breakpoint(mediumdesktop) {
        min-height: 625px;
      }

      @include breakpoint(largedesktop) {
        min-height: 630px;
      }

      &:after {
        background: rgba(0, 0, 0, 0.3);
      }

      &-hangs {
        @include breakpoint(tablet) {
          padding-top: 25%;
        }

        @include breakpoint(laptop) {
          padding: 0;
        }
      }
    }

    .siteorigin-widget-tinymce {
      display: block;
      max-width: 830px;
      width: 88%;
      margin: 0 auto 15px;

      @include breakpoint(desktop) {
        margin: 65px auto 45px;
      }

      p .btn {
        margin: 35px auto 0;

        @include breakpoint(tablet) {
          margin: 35px 0;
        }
      }

      h2 {
        @include breakpoint(tablet) {
          width: 85%;
        }
      }
    }

    .so-panel {
      margin: 15px auto;

      @include breakpoint(laptop) {
        margin: 35px auto;
      }
    }
  }
