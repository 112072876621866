/* ==========================================================================
   Layout / Bloc de temoignages
   ========================================================================== */

  .widget_block-testimonials-widget:nth-child(even) {
    .bloc-testimonials {
      @include breakpoint(tablethor) {
        flex-direction: row-reverse;
      }

      &.bg-white {
        @include breakpoint(tablet) {
          flex-direction: row-reverse;
        }

        .testimonials-img.bg-white+.testimonials-text {
          @include breakpoint(tablet) {
            margin-left: 0;
            margin-right: 5%;
          }

          @include breakpoint(tablethor) {
            margin-left: 0;
            margin-right: -6%;
          }
        }
      }
    }

    .testimonials-img + .testimonials-text {
      @include breakpoint(tablethor) {
        margin-right: -3%;
        margin-left: 0;
      }
    }

    .testimonials-img {
      &.bg-white {
        @include breakpoint(tablethor) {
          justify-content: flex-end;
        }
      }
    }
  }

  .bloc-testimonials {
    @include wrapper('min');

    @include breakpoint(tablet) {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 35px;
    }

    @include breakpoint(laptop) {
      margin-top: 0;
      margin-bottom: 75px;
    }

    &.bg-white {
      @include breakpoint(tablet) {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 35px;
      }

      @include breakpoint(laptop) {
        margin-top: 50px;
        margin-bottom: 125px;
      }
    }
  }

  .testimonials-img {
    background-size: cover;
    background-position: center center;
    border: 5px solid $yellowColor;
    border-bottom-width: 0;
    background-color: $greyLightBack;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 220px;
    padding: 15px 0;
    position: relative;

    @include breakpoint(tablet) {
      flex-basis: 35%;
      border: none;
      min-height: 75px;
    }

    @include breakpoint(tablethor) {
      flex-basis: 33.3333%;
      border: none;
      min-height: 180px;
    }

    @include breakpoint(desktop) {
      min-height: 250px;
    }

    &.bg-white {
      border: 0;
      background-color: $backgroundLight;
      padding: 0 0 10px;

      @include breakpoint(tablet) {
        flex-basis: 30%;
        border: none;
        min-height: 250px;
        padding: 15px 0;

        &.imgcover {
          flex-basis: 40%;
          min-height: 155px;
        }
      }

      @include breakpoint(tablethor) {
        justify-content: flex-start;
        flex-basis: 33.3333%;
        border: none;
        min-height: 250px;

        &.imgcover {
          flex-basis: 33.3333%;
          min-height: 250px;
        }
      }

      img {
        @include breakpoint(tablet) {
          max-width: 220px;
        }

        @include breakpoint(tablethor) {
          max-width: 70%;
        }

        @include breakpoint(laptop) {
          max-width: 60%;
        }
      }

      & + .testimonials-text {
        border-top-width: 5px;

        @include breakpoint(tablet) {
          flex-basis: 55%;
          margin-left: 5%;
          margin-top: 40px;

          &.textimgcover {
            flex-basis: 60%;
            margin-left: -15%;
          }
        }

        @include breakpoint(tablethor) {
          flex-basis: 70.6666%;
          margin-left: -6%;
          margin-top: 60px;

          &.textimgcover {
            flex-basis: 70.6666%;
            margin-left: -6%;
          }
        }
      }
    }

    img {
      padding-top: 10px;
      padding-bottom: 10px;
      max-width: 200px;
      height: auto;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      @include breakpoint(tablet) {
        max-width: 125px;
        padding-right: 5px;
      }

      @include breakpoint(tablethor) {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        max-width: 50%;
        height: auto;
      }
    }

    & + .testimonials-text {
      @include breakpoint(tablet) {
        flex-basis: 75%;
        margin-left: -3%;
        margin-top: 20px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 70.6666%;
        margin-left: -3%;
        margin-top: 60px;
      }
      position: relative;
      z-index: 1;
    }
  }

  .testimonials-text {
    flex-basis: 100%;
    border: 5px solid $yellowColor;
    border-top-width: 0;
    padding: 25px;
    background-color: $whiteColor;

    @include breakpoint(phablet) {
      padding: 35px 45px;
    }

    @include breakpoint(tablet) {
      border-top-width: 5px;
    }

    .name {
      font-size: 22px;
      margin-bottom: 4px;

      & + p {
        margin-top: 10px;
      }
    }

    .job {
      font-size: 18px;
      font-weight: normal;
      color: #909090;
      margin-bottom: 15px;
    }

    .btn-main {
      margin-bottom: 0;
    }

    &.bg-yellow {
      background: $yellowColor;

      p {
        font-family: $alternativeFont;
        font-size: 17px;
        line-height: 1.4;
      }

      .job {
        color: #716200;
      }
    }
  }

  .page-id-561  {
    .bloc-testimonials {
      @include breakpoint(tablet) {
        margin-top: 0;
        margin-bottom: 5px;
      }
    }
  }