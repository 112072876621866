/* ==========================================================================
   Page / Article
   ========================================================================== */

  .article {
    .header {
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 0;

        @include breakpoint(tablethor) {
          background: rgba(0, 0, 0, 0.3);
        }
      }

      &.smallHeader {
        border-bottom: 2px solid $greyLightBack;
        background: url('./images/visuel-noheader.jpg') center bottom/cover no-repeat;

        @include breakpoint(tablet) {
          min-height: inherit;
          margin-bottom: 50px;
        }

        @include breakpoint(laptop) {
          margin-bottom: 90px;
        }

        .header-hangs {
          .h1-title, .subtitle {
            color: $whiteColor;
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
          }

          .post-categories li {
            display: inline-block;
          }
        }

        .breadcrumb {
          color: $whiteColor;

          a {
            color: $whiteColor;

            &:after {
              background: $whiteColor;
            }
          }
        }

        &:after {
          background: rgba(0, 0, 0, 0.4);
        }

        &:before {
          opacity: 1;
        }
      }

      &.bg-top {
        background-position: top center;
      }
      &.bg-center {
        background-position: center center;
      }

      &.noImgCover {
        .header-hangs {
          .h1-title, .subtitle {
            color: $blackColor;
            text-shadow: 0 0 0 transparent;
          }
        }

        .breadcrumb {
          color: $blackColor;

          li:not(:last-child):after {
            background: url(images/arrow.svg) center center/10px 10px no-repeat;
          }

          a {
            color: $blackColor;

            &:after {
              background: $blackColor;
            }
          }
        }

        &:after {
          background: rgba(0, 0, 0, 0.04);
        }

        &:before {
          opacity: 0.2;
        }
      }
    }
  }