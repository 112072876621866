/* ==========================================================================
   Layout / Bloc de chiffres cles
   ========================================================================== */

  .blockeysnumber {
    @include wrapper('min');
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    @include breakpoint(tablethor) {
      justify-content: center;
    }

    &-title {
      @extend .h2-title;
      display: block;
      flex-basis: 100%;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 20px;
    }

    &-intro {
      display: block;
      flex-basis: 100%;
      text-align: center;
      font-family: $alternativeFont;
      margin-bottom: 15px;

      @include breakpoint(phablet) {
        margin-bottom: 30px;
      }

      @include breakpoint(tablethor) {
        margin-bottom: 60px;
      }
    }
  }

  /* classe en dur dans le page-builder de la page qui-sommes-nous pour gerer l-affichage des chiffres */

  @media (max-width: 1385px) {
    .break-laptop {
      flex-direction: column;
      div[id^='pgc-'] {
        width: 100% !important;
      }
    }
  }

  .keynumber {
    text-align: center;
    flex: 1 0 98%;
    max-width: 100%;
    min-width: 200px;
    margin: 20px 5% 30px;

    @include breakpoint(phablet) {
      flex: 1 0 48%;
      max-width: 40%;
    }

    @include breakpoint(tablet) {
      flex: 1 0 31%;
      max-width: 200px;
      margin: 20px 8% 40px;
    }

    @include breakpoint(tablethor) {
      margin: 20px 5% 40px;
    }

    @include breakpoint(laptop) {
      flex: 1 0 18%;
      max-width: 20%;
      margin: 5px 6% 50px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 30%;
      }
    }

    .wysiwyg {
      text-align: center;
      font-size: 18px;
      font-family: $special-font-family;
      font-weight: 400;
      line-height: 0.94;
    }
  }

  .keynumber-title {
    font-family: "Diemme Script";
    font-size: 70px;
    line-height: 60px;
    padding-bottom: 6px;
    .keynumber:nth-child(1) & {
      color: #5a81c2;
    }
    .keynumber:nth-child(2) & {
      color: #f26c50;
    }
    .keynumber:nth-child(3) & {
      color: #44c1c3;
    }
    .keynumber:nth-child(4) & {
      color: #73c169;
    }
    .keynumber:nth-child(5) & {
      color: #848eb5;
    }
    .keynumber:nth-child(6) & {
      color: #2d4386;
    }
    .keynumber:nth-child(7) & {
      color: #f26c50;
    }
    .keynumber:nth-child(8) & {
      color: #6ccde7;
    }
  }
