@charset 'UTF-8';

// Base
@import 'base/fonts';
@import 'base/variables';
@import 'base/mixins';
@import 'base/base';
@import 'base/hacks';
@import 'base/form';

// Components
@import 'components/button';
@import 'components/titles';
@import 'components/breadcrumb';
@import 'components/card';
@import 'components/cardoffer';
@import 'components/newscard';
@import 'components/wysiwyg';
@import 'components/keypoint';
@import 'components/pushproduct';
@import 'components/slick';
@import 'components/tarteaucitron';
@import 'components/tarteaucitron-surcharge';
@import 'components/pagenavi';

// Layout
@import 'layout/navigation';
@import 'layout/header';
@import 'layout/videoblock';
@import 'layout/universes';
@import 'layout/offers';
@import 'layout/blocComposed';
@import 'layout/sliderbrand';
@import 'layout/newsblock';
@import 'layout/blockintro';
@import 'layout/textimg';
@import 'layout/blockeys';
@import 'layout/contactblock';
@import 'layout/categories';
@import 'layout/blockquote';
@import 'layout/discoveroffers';
@import 'layout/blockeynumber';
@import 'layout/blocktestimonials';
@import 'layout/blocktextnumbers';
@import 'layout/blocsliderinfos';
@import 'layout/blockformlanding';
@import 'layout/blockpictos';
@import 'layout/logosgrid';
@import 'layout/blockcontact';
@import 'layout/engagements';
@import 'layout/footer';

// Pages
@import 'pages/homepage';
@import 'pages/page';
@import 'pages/offer';
@import 'pages/newspage';
@import 'pages/landing';
@import 'pages/sitemap';
@import 'pages/search';
@import 'pages/singlenews';
