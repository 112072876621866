/* ==========================================================================
   Pages / Résultats de recherche
   ========================================================================== */

  .results {
    &-content {
      @include wrapper('min');
    }

    &-elem {
      display: table;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 25px;
      border-bottom: 1px solid darken($greyLightBack, 10%);

      @include breakpoint(phablet) {
        padding-bottom: 30px;
      }
    }

    &-image {
      display: block;

      @include breakpoint(phablet) {
        display: table-cell;
        float: left;
        width: 225px;
        margin-top: 5px;
        margin-right: 15px;
      }

      @include breakpoint(tablet) {
        width: 265px;
      }

      @include breakpoint(tablethor) {
        margin-right: 35px;
        margin-top: 2px;
      }

      img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
          margin-bottom: 0px;
        }
      }
    }

    &-text {
      display: block;
      text-align: left;
      padding: 0 10px;

      @include breakpoint(phablet) {
        display: table-cell;
        width: 100%;
        vertical-align: top;
        //width: 60%;
      }

      @include breakpoint(tablet) {
        //width: 72%;
      }
    }

    &-infos {
      margin-bottom: 10px;

      @include breakpoint(phablet) {
        margin-bottom: 30px;
      }

      li {
        display: inline-block;
        list-style-type: none;
        color: $blackColor;
        font-family: $alternativeFont;
        text-align: left;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 13px;
        letter-spacing: 0.04rem;
        padding-right: 15px;

        &.type:before {
          content: ' ';
          display: inline-block;
          width: 17px;
          height: 20px;
          margin-right: 10px;
          position: relative;
          top: 5px;
          background: url(images/picto-pagetype.svg) center center/17px 22px no-repeat;
        }

        &.date:before {
          content: ' ';
          display: inline-block;
          width: 17px;
          height: 20px;
          margin-right: 8px;
          position: relative;
          top: 5px;
          background: url(images/picto-date.svg) center center/17px 22px no-repeat;
        }
      }
    }

    &-title {
      @extend .h3-title;
      color: $blackColor;
      margin-bottom: 15px;
    }

    .nav-links {
      font-weight: 400;

      a {
        margin: 2px;
        margin-bottom: 8px;
        display: inline-block;
        padding: 8px 13px;
        border: 1px solid darken($greyLightBack, 10%);

        &:hover {
          background: $redColor;
          color: $whiteColor;
          border: 1px solid $redColor;
        }
      }

      .prev, .next {
        font-size: 18px;
        padding: 6px 13px 8px;
      }

      .current {
        margin: 1px;
        padding: 8px 13px;
        background: $redColor;
        color: $whiteColor;
        border: 1px solid $redColor;
      }
    }
  }