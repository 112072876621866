/* ==========================================================================
   Layout / Bloc texte avec ou sans image
   ========================================================================== */

  .textimg {
    @include wrapper();
    background: $greyLightBack;

    @include breakpoint(tablet) {
      margin-bottom: 25px;
    }

    @include breakpoint(tablethor) {
      display: flex;
      flex-direction: row;
    }

    @include breakpoint(laptop) {
      margin-bottom: 55px;
    }

    &.textonly {
      max-width: 1000px;

      .textimg-text {
        flex-basis: 100%;
      }
    }

    &.bg-yellow {
      background: $yellowColor;

      .firstitle-bar {
        background: $backgroundLight;
      }
    }
    &.bg-white {
      background: $backgroundLight;

      .textimg-text {
        width: 100%;
        padding: 25px 5px;

        @include breakpoint(phablet) {
          width: 95%;
          padding: 35px 0;
        }

        @include breakpoint(tablethor) {
          padding: 10px 60px 25px 40px;
        }

        @include breakpoint(laptop) {
          padding: 15px 100px 35px 60px;
        }
      }

      .textimg-img.right+.textimg-text {
        @include breakpoint(tablethor) {
          padding: 0 100px 25px 60px;
        }

        @include breakpoint(laptop) {
          padding: 0 160px 35px 80px;
        }
      }
    }
    &.bg-white-round {
      @include wrapper('min');
      background: $backgroundLight;

      @include breakpoint(tablethor) {
        display: flex;
        align-items: center;
      }

      .textimg-text {
        width: 100%;
        padding: 25px 5px;

        @include breakpoint(phablet) {
          width: 95%;
          padding: 35px 0;
        }

        @include breakpoint(tablet) {
          padding: 35px 0 0;
        }

        @include breakpoint(tablethor) {
          padding: 10px 20px 15px 65px;
          flex-basis: calc(100% - 455px);
        }

        @include breakpoint(laptop) {
          padding: 15px 10px 20px 60px;
          flex-basis: calc(100% - 530px);
        }

        @include breakpoint(mediumdesktop) {
          padding: 0 10px 15px 5px;
          flex-basis: calc(100% - 560px);
        }
      }

      .textimg-img.right+.textimg-text {
        margin-bottom: 5px;
        padding-top: 25px;

        @include breakpoint(tablet) {
          padding-top: 35px;
          margin-bottom: 45px;
        }

        @include breakpoint(tablethor) {
          padding: 0 25px 15px 10px;
          flex-basis: calc(100% - 405px);
          margin-bottom: 0;
        }

        @include breakpoint(laptop) {
          padding: 0 45px 15px 10px;
          flex-basis: calc(100% - 530px);
        }

        @include breakpoint(mediumdesktop) {
          padding: 0 55px 15px 10px;
          flex-basis: calc(100% - 550px);
        }
      }

      .textimg-img {
        @include breakpoint(mobileonly) {
          border-radius: 1000px;
          width: 225px;
          height: 225px;
          flex-basis: 225px;
          margin: auto;
        }

        @include breakpoint(tablethor) {
          border-radius: 1000px;
          width: 365px;
          height: 365px;
          flex-basis: 365px;
        }

        @include breakpoint(laptop) {
          width: 425px;
          height: 425px;
          flex-basis: 425px;
        }

        @include breakpoint(mediumdesktop) {
          width: 400px;
          height: 400px;
          flex-basis: 400px;
          margin-right: 3%;
        }
      }
    }
    &.bg-white-border-yellow {  background: $backgroundLight; box-shadow: inset 0 0 0 4px $yellowColor; .textimg-img { margin: 4px;  top: 4px; @include breakpoint(tablethor) { top: 0; }}}

    &-img {
      position: relative;
      overflow: hidden;
      height: 175px;
      background-position: center center;
      background-size: cover;

      @include breakpoint(phablet) {
        height: 300px;
      }

      @include breakpoint(tablet) {
        height: 330px;
      }

      @include breakpoint(tablethor) {
        flex-basis: 45%;
        height: inherit;
      }

      @include breakpoint(laptop) {
        flex-basis: 40%;
      }

      &.right {
        order: 1;

        & + .textimg-text {
          order: 0;

          @include breakpoint(tablethor) {
            padding: 45px 60px 65px;
          }

          @include breakpoint(laptop) {
            padding: 55px 80px 80px;
          }
        }
      }

      img {
        display: none;
      }
    }

    &-text {
      width: calc(100% - 55px);
      padding: 30px 15px 35px;
      order: 1;

      @include breakpoint(phablet) {
        width: calc(100% - 90px);
        padding: 45px 45px;
      }

      @include breakpoint(tablet) {
        width: calc(100% - 120px);
        padding: 45px 60px;
      }

      @include breakpoint(tablethor) {
        width: auto;
        flex-basis: 50%;
        padding: 45px 60px 45px 40px;
      }

      @include breakpoint(laptop) {
        flex-basis: 55%;
        padding: 45px 100px 45px 60px;
      }

      .firstitle {
        &-bar {
          display: block;
          width: 100px;
          height: 4px;
          margin-top: 20px;
          background: $yellowColor;
        }
      }

      .btn {
        margin: 30px auto 0;

        @include breakpoint(phablet) {
          margin: 40px 0 0;
        }
      }

      &.wysiwyg {
        .alignleft {
          float: left;
          margin-right: 20px;
          margin-bottom: 5px;
        }
        .alignright {
          float: right;
          margin-left: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .widget_block-text-img-widget + .widget_sow-editor {
    @include breakpoint(laptop) {
      margin-top: 35px;
      margin-bottom: 45px!important;
    }
  }
  .welcomekit-jobs-list{

    .welcomekit-jobs-list-item{

      background: $greyLightBack;

      @include breakpoint(phablet) {
        padding: 45px 45px;
      }

      @include breakpoint(tablet) {
        padding: 45px 60px;
      }

      @include breakpoint(tablethor) {
        padding: 45px 60px 45px 40px;
      }

      @include breakpoint(laptop) {
        padding: 45px 100px 45px 60px;
        margin-bottom: 55px;
      }
    }

    .welcomekit-jobs-list-item{
      &:before{
        display: none !important;
      }
    }

    .welcomekit-job-contractType,
    .welcomekit-job-officeCity,
    .welcomekit-job-department{
      padding: 0 15px;
    }

    .welcomekit-jobs-list-item-link{
      text-decoration: none !important;
    }

    .welcomekit-job-name{
      display: flex;
      flex-direction: column;
      font-family: $special-font-family;
      font-size: 28px;
      line-height: 1;
      letter-spacing: 1.1px;
      font-weight: 700;
      margin-bottom: 30px;

      &:after {
        content: '';
        display: block;
        width: 100px;
        height: 4px;
        margin-top: 20px;
        background: $yellowColor;
      }
    }

    .welcomekit-job-infos{
      display: flex;
      margin-bottom: 0 !important;
    }
  }

