body.tarteaucitron-modal-open div#tarteaucitronRoot {
  &.tarteaucitronBeforeVisible:before {
    opacity: 0;
  }
}

body div#tarteaucitronRoot {
  &.tarteaucitronBeforeVisible:before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background: #FFF;
    top: 0;
    left: 0;
    z-index: 999;
    opacity: .7;
  }

  div#tarteaucitronAlertBig {
    display: block;
    opacity: 1;
    max-height: 90vh;
    overflow: auto;
    max-width: 90vw;
    padding-top: 50px;
    box-sizing: border-box;
    box-shadow: 0 0 80px 10px #141913;
    background: #fff;
    text-align: center;
    z-index: 2147483645;

    @media (min-width: 768px) {
      width: calc(100% - 40px);
      max-width: 600px;
      box-sizing: content-box;

      display: flex;
      flex-wrap: wrap;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
  div#tarteaucitronAlertBig:before {
    font-size: 22px;
    font-weight: bold;
  }

  span#tarteaucitronDisclaimerAlert {
    color: black;
    font-size: 15px;
    line-height: 1.3;
    padding: 0;
    font-family: "Calibri",sans-serif;
    box-sizing: initial;
  }

  #tarteaucitronAlertBig #tarteaucitronCloseAlert,
  #tarteaucitronAlertBig #tarteaucitronPersonalize,
  #tarteaucitronAlertBig #tarteaucitronPersonalize2,
  .tarteaucitronCTAButton,
  #tarteaucitron #tarteaucitronPrivacyUrl,
  #tarteaucitron #tarteaucitronPrivacyUrlDialog,
  .tarteaucitronDeny,
  .tarteaucitronAllow {
    background: #FFF;
    border: 1px solid #000;
    color: #000;
    cursor: pointer;
    display: inline-block;
    font-size: 16px!important;
    font-weight: 400;
    line-height: 1.2;
    padding: 10px 15px;
    text-decoration: none;
    margin-left: 7px;
    border-radius: 50px;
  }
  #tarteaucitronAlertBig #tarteaucitronPersonalize2.tarteaucitronCTAButton.tarteaucitronAllow {
    background-color: #000;
    color: #FFF;
    &:hover {
      background-color: #ffdd02;
      color: #000;
      .tarteaucitronCheck:before {
        color: #000;
      }
    }
  }
  #tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
    background: #FFF;
    color: #000;
  }

  #tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny,
  .tarteaucitronDeny {
    background: #FFF;
    color: #000;
  }

  .tarteaucitronAsk .tarteaucitronCheck:before,
  .tarteaucitronCheck:before,
  .tarteaucitronCross:before {
    content: '\2713';
    display: inline-block;
    color: #000;
  }
  .tarteaucitronCheck:before {
    color: #FFF;
  }

  .tarteaucitronCross:before {
    content: '\2717';
  }

  #tarteaucitronServices .tarteaucitronIsSelected .tarteaucitronCheck:before,
  #tarteaucitronServices .tarteaucitronIsSelected .tarteaucitronCross:before,
  .tarteaucitronIsDenied .tarteaucitronCross:before,
  .tarteaucitronIsAllowed .tarteaucitronCheck:before {
    color: #FFF;
  }

  #tarteaucitronAlertBig #tarteaucitronCloseAlert,
  #tarteaucitron #tarteaucitronPrivacyUrl {
    background: #fff;
    color: #000;
    font-size: 13px;
    margin-left: 7px;
    padding: 10px 15px;
  }

  #tarteaucitronManager:hover {
    background: #000 !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
    display: block !important;
    max-width: none;
    position: relative;
    padding: 10px 20px;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle button.catToggleBtn {
    cursor: default;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
  .tac_activate .tarteaucitronAllow {
    background: #FFF;
    color: #000;
  }

  .tarteaucitronLine .tarteaucitronAllow,
  .tarteaucitronIsAllowed .tarteaucitronDeny {
    opacity: 1 !important;
  }

  #tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny,
  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
    background: #d7123f;
    color: #FFF;
  }

  #tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected,
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow,
  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
    background: #57b97f;
    color: #FFF;
  }

  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
    background-color: #fbda26;
  }

  #tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
    background-color: gray;
    border-radius: 5px;
    display: block;
    margin-bottom: 1px;
    overflow: hidden;
    width: 100%;
    #tarteaucitronDotGreen,
    #tarteaucitronDotYellow,
    #tarteaucitronDotRed {
      display: block;
      float: left;
      height: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  body div#tarteaucitronAlertBig button {
    margin: 0 0 0 7px !important;
  }
}
@media (min-width: 768px) {
  #tarteaucitronPersonalize2{
    float: left;
    left: 60%;
    position: relative;
  }
  #tarteaucitronAllDenied2{
    float: left;
    left: -13%;
    position: relative;
  }
  #tarteaucitronCloseAlert{
    float: left;
    left: -12%;
    position: relative;
  }
}
