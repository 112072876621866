/* ==========================================================================
   Layout / Block Logos Grille
   ========================================================================== */

  .logosgrid {
    @include wrapper();

    &-text {
      margin-bottom: 20px;

      @include breakpoint(laptop) {
        margin-bottom: 70px;
      }
    }

    &-content {
      @include breakpoint(phablet) {
        display: flex;
        flex-wrap: wrap;
      }

      .logo {
        width: 100%;
        display: block;
        margin-bottom: 15px;
        text-align: center;

        @include breakpoint(phablet) {
          flex-basis: 50%;
          margin-bottom: 25px;
        }

        @include breakpoint(tablet) {
          flex-basis: 33.3%;
        }

        @include breakpoint(tablethor) {
          flex-basis: 20%;
          margin-bottom: 55px;
          text-align: left;
        }

        img {
          max-width: 60%;
          height: auto;
          margin: auto;

          @include breakpoint(tablethor) {
            max-width: 70%;
          }
        }
      }
    }
  }