/* ==========================================================================
   Components / Buttons
     ========================================================================== */

  button {
    background: transparent;
    border: 0;
    cursor: pointer;

    &:focus {
      outline-style: none;
    }
  }

  .btn {
    text-decoration: none;

    &:focus {
      outline-style: none;
    }
  }

  .btn-main {
    display: table;
    font-size: 14px;
    font-weight: 900;
    font-family: $special-font-family;
    color: $whiteColor;
    background: $blackColor;
    transition: all $durationSmall linear;
    letter-spacing: 1px;
    text-align: center;
    border-radius: 30px;
    padding: 14px 25px;
    margin: 35px 0;

    &:hover {
      background: darken($yellowColor, 2%);
      transition: all $durationSmall linear;
    }
  }

  .btn-main-larger {
    @extend .btn-main;
    padding: 14px 40px;
    transition: all $durationSmall $easeOutCubic;
    margin: 20px auto;

    @include breakpoint(tablet) {
      margin: 20px 0;
    }
  }

  .btn-main-hovempty {
    @extend .btn-main;
    padding: 12px 40px;
    border: 2px solid $blackColor;
    transition: all $durationSmall $easeOutCubic;
    margin: 20px 0;

    &:hover {
      background: transparent;
      color: $blackColor;
      transition: all $durationSmall $easeOutCubic;
    }
  }

  .btn-main-white {
    @extend .btn-main;
    display: block;
    width: 60%;
    margin: 15px auto 0;
    background: $backgroundLight;
    color: $blackColor;
    padding: 15px 25px;
    transition: all $duration $easeInOutCubic;

    &:hover {
      transition: all $duration $easeInOutCubic;
    }
  }

  .btn-main-black {
    @extend .btn-main;
    display: block;
    width: 55%;
    margin: 15px auto 0;
    background: $backgroundLight;
    border: 3px solid $backgroundLight;
    color: $blackColor;
    padding: 11px 20px;
    transition: all $duration $easeInOutCubic;

    &:hover {
      background: transparent;
      color: $whiteColor;
      transition: all $duration $easeInOutCubic;
    }
  }

  .btn-main-white-border {
    @extend .btn-main;
    color: $whiteColor;
    padding: 12px 45px;
    background: transparent;
    border: 2px solid $whiteColor;
    transition: all $duration $easeInOutQuart;
    margin: 20px auto;

  &:hover {
    background: $whiteColor;
    color: $blackColor;
    transition: all $duration $easeInOutQuart;
  }
}

  .btn-main-border {
    @extend .btn-main;
    color: $blackColor;
    padding: 12px 30px;
    background: transparent;
    border: 2px solid $blackColor;
    transition: all $duration $easeInOutQuart;

    &:hover {
      background: $blackColor;
      color: $whiteColor;
      transition: all $duration $easeInOutQuart;
    }
  }

  .btn-readmore {
    display: table;
    font-family: $alternativeFont;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    padding: 0;
    cursor: pointer;

    &:before {
      content: ' ';
      display: block;
      width: 0;
      height: 2px;
      margin-top: 3px;
      background: $yellowColor;
      transition: all $duration $easeInOutQuart;
      position: absolute;
      left: 0;
      bottom: -3px;
    }

    &:after {
      content: ' ';
      display: inline-block;
      width: 8px;
      height: 7px;
      background: url('images/double-arrow.svg') center center/ 8px 7px no-repeat;
      transition: all $durationSmall $easeInOutQuart;
      margin-left: 6px;
      position: relative;
      bottom: 1px;
    }

    &:hover {
      &:after {
        margin-left: 8px;
        transition: all $durationSmall $easeInOutQuart;
      }

      &:before {
        width: 86%;
        transition: all $duration $easeInOutQuart;
      }
    }
  }

  .btn-topage {
    display: block;
    opacity: 0;
    width: 35px;
    height: 35px;
    text-indent: -9999px;
    position: fixed;
    right: 8%;
    bottom: 3%;
    border: 0;
    border-radius: 500px;
    background: $blackColor url('images/icone-fleche.svg') center center/ 10px 15px no-repeat;
    transform: rotate(-90deg);
    //box-shadow: 1px 2px 10px 0 rgba(0,0,0,0.1);
    transition: all $duration $easeInOutQuart;
    z-index: 4;
    cursor: pointer;

    @include breakpoint(phablet) {
      right: 3%;
      bottom: 8%;
    }

    @include breakpoint(tablet) {
      right: 5%;
    }

    &.show {
      opacity: 1.0;
      transition: all $duration $easeInOutQuart;
    }

    &:hover {
      background: darken($yellowColor, 3%)  url('images/icone-fleche.svg') center center/ 10px 15px no-repeat;
      transform: rotate(-90deg) translateX(5px);
      transition: all $duration $easeInOutQuart;
    }
  }

  .btn-black {
    display: block;
    text-transform: uppercase;
    font-family: $alternativeFont;
    background: $blackColor;
    padding: 10px 20px;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.02rem;
    color: $whiteColor;
    margin-top: 20px;
    transition: all $duration $easeOutCubic;

    @include breakpoint(phablet) {
      margin-top: 30px;
    }

    &:hover {
      background: $yellowColor;
      transition: all $duration $easeOutCubic;
    }
  }

  .btn-submit {
    display: block;
    text-transform: uppercase;
    font-family: $alternativeFont;
    background: $blackColor;
    border: 0;
    padding: 15px 30px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.02rem;
    color: $whiteColor;
    margin-top: 20px;
    transition: all $duration $easeOutCubic;
    outline-style: none;
    cursor: pointer;

    @include breakpoint(phablet) {
      margin-top: 30px;
    }

    &:hover {
      background: darken($yellowColor, 2%);
      transition: all $duration $easeOutCubic;
    }
  }
