/* ==========================================================================
   Layout / Bloc de citation
   ========================================================================== */

  .blockquote {
    @include wrapper();
    width: 85%;
    position: relative;
    margin-top: 55px;

    @include breakpoint(tablet) {
      width: calc(80% - 25px);
      max-width: 850px;
      margin: 45px auto;
    }

    @include breakpoint(laptop) {
      margin: 35px auto;
    }

    &:nth-child(2n) {
      &:before {
        display: none;
      }
    }

    &:before {
      content: ' ';
      display: block;
      width: 15%;
      height: 2px;
      background: $yellowColor;
      margin: 20px auto;

      @include breakpoint(phablet) {
        margin: 35px auto;
      }
    }

    &:after {
      content: ' ';
      display: block;
      width: 15%;
      height: 2px;
      background: $yellowColor;
      margin: 35px auto 15px;

      @include breakpoint(tablet) {
        margin: 35px auto;
      }
    }

    &-icon {
      display: block;
      float: left;
      background: $yellowColor;
      width: 80px;
      height: 80px;
      vertical-align: top;
      position: absolute;
      top: 15px;
      right: -25px;
      transform: rotate(180deg);
      margin-right: 20px;
      margin-top: -15px;
      opacity: 0.4;
      z-index: 0;

      @include breakpoint(phablet) {
        margin-bottom: -5px;
        opacity: 1;
        position: relative;
        transform: rotate(0deg);
        left: 0;
        right: 0;
        top: 0;
      }

      @include breakpoint(tablethor) {
        margin-right: 40px;
        margin-bottom: 20px;
      }

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background: url('images/mask-quote.svg') center center/cover no-repeat;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &-content {
      display: block;
      width: 100%;
      vertical-align: top;
      position: relative;
      z-index: 1;
    }

    &-title {
      font-family: $special-font-family;
      font-size: 22px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.24;
      letter-spacing: normal;
      margin-bottom: 3px;

      @include breakpoint(tablet) {
        display: inline-block;
      }
    }

    &-subtitle {
      color: $yellowColor;
      font-family: $sans-serif-font;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.01rem;
      margin-bottom: 15px;

      @include breakpoint(tablet) {
        display: inline-block;

        &:before {
          content: '-';
          margin: 0 10px;
          color: $blackColor;
        }
      }
    }

    &-text {
      line-height: 1.2;
      letter-spacing: -0.02rem;
      opacity: 0.8;

      @include breakpoint(tablet) {
        transform: skewX(-5deg);
      }

      p {
        display: block;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }