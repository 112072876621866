/* ==========================================================================
   Components / Page Navi
   ========================================================================== */

  .wp-pagenavi {
    clear: both;
    display: block;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
    position: relative;
    width: auto;
    max-width: 100%;
    font-family: $alternativeFont;
    text-align: center;
  }

  .wp-pagenavi a, .wp-pagenavi span {
    font-size: 14px;
    text-decoration: none;
    background: $greyLightBack;
    padding: 10px 15px;
    margin: 3px;
    color: $blackColor;
    display: inline-block;
  }

  .wp-pagenavi a:hover, .wp-pagenavi span.current {
    color: $whiteColor;
    background: $yellowColor;
  }

  .wp-pagenavi span.current {
    font-weight: bold;
    background: $blackColor;
    color: $whiteColor;
    border: 0;
  }

  .wp-pagenavi span.pages {
    display: none;
  }

  .wp-pagenavi a.last  {
    font-weight: 700;
  }