/* ==========================================================================
 Layout / Actualités mise en avant sur une page
 ========================================================================== */

  .newsblock {
    @include wrapper();
    margin-bottom: 25px;
    position: relative;
    z-index: 2;

    @include breakpoint(phablet) {
      margin-bottom: 45px;
    }

    @include breakpoint(desktop) {
      margin-bottom: 65px;
    }

    .h1-title {
      margin-bottom: 20px;
    }

    &-intro {
      margin-bottom: 20px;

      @include breakpoint(tablethor) {
        width: 65%;
        margin-bottom: 40px;
      }
    }

    &-categories {
      margin-top: 15px;

      ul li {
        display: inline-block;
        list-style-type: none;
        padding: 0;
        margin: 0 2px 0 0;

        &:before {
          display: none;
        }

        &.current a {
          background: $yellowColor;
          cursor: default;
        }

        .btn-black {
          margin-top: 6px;
        }

        a span {
          font-family: $font-stack-common;
          font-size: 12px;
          margin-right: 2px;
        }
      }
    }

    &-news {
      margin-bottom: 30px;

      @include breakpoint(tablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @include breakpoint(tablethor) {
        flex-wrap: nowrap;
      }

      .btn-main-larger {
        margin: 20px auto;
      }
    }
  }